import styled from "styled-components";
import { TitlePage } from "../../../components/Landing/Title/styled";

export const SolutionContainer = styled.div`
  
  ${TitlePage} {
    display: inline-block;
  }
`;
export const SolutionTitle = styled.div`
  position: relative;
  display: inline;
  &::before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    background: url("/img/Landing/title_effect.png") no-repeat center / 100%
      auto;
    width: 104px;
    height: 145px;
  }
  @media (max-width: 1023px) {
    left: 10px;
    bottom: -3px;
    img {
      width: 45px;
      height: 23px;
      object-fit: contain;
    }
    &::before {
      left: -26px;
    }
  }
`;
export const SolutionList = styled.ul`
  & > li {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 16px 12px;
    border-radius: 8px;
    background: rgba(41, 41, 41, 0.49);
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    figure {
      width: 24px;
      height: 24px;
    }
    p {
      color: #a5a5a5;
      font-size: 15px;
      font-weight: 500;
    }
  }
  @media (max-width: 1023px) {
    & > li {
      p {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
`;
