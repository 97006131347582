import styled from "styled-components";
import {
  BlockContainer,
  BlockHeader,
} from "../../components/Common/Block/styled";
import { CardWrapper } from "../../components/Common/Card/styled";
import { AddressCopy, ButtonAction } from "../../Layout/styled";
import { ProgressContainer } from "../../components/Common/Progress/styled";

export const DashboardContainer = styled.div``;
export const DashboardInfo = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  @media screen and (max-width: 767px) {
    display: block;
    border-radius: 10px;
    background: #272727;
    padding: 5px;
    ${CardWrapper} {
      margin-bottom: 5px;
    }
  }
`;
export const DashboardBlocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: calc(100% - 306px);
  ${BlockContainer} {
    width: calc(50% - 30px);
    max-width: unset;
    ${BlockHeader} {
      margin-bottom: 24px;
    }
  }
  @media screen and (max-width: 767px) {
    width: auto;
    gap: 4px;
    ${BlockContainer} {
      width: calc(50% - 26px);
    }
  }
`;
export const DashboardTable = styled.div``;
export const TableMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;
export const TableInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 5px;
  & > p {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #5fa4ff;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    figure {
      width: 21px;
      height: 21px;
    }
  }
  ${AddressCopy} {
    color: #c0c0c0;
    font-size: 13px;
  }
  & > div {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    & > p {
      font-size: 13px;
      color: #929292;
    }
  }
`;
export const TableAction = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${ProgressContainer} {
    width: 120px;
  }
  ${ButtonAction} {
    margin-left: auto;
    margin-right: 0;
  }
`;
