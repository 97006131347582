import {
  ProgressContainer,
  ProgressContent,
  ProgressLine,
  ProgressWrapper,
} from "./styled";

const ProgressCommon = ({ content, progress, backgroundColor }: any) => {
  return (
    <ProgressContainer>
      <ProgressWrapper>
        <ProgressLine
          style={{
            background: `${backgroundColor}`,
            width: progress > 0 ? `${progress}%` : "0",
          }}
        >
          {progress > 0 && content ? (
            <ProgressContent>{content}</ProgressContent>
          ) : (
            <></>
          )}
        </ProgressLine>
      </ProgressWrapper>
    </ProgressContainer>
  );
};

export default ProgressCommon;
