import { useRef, useState } from "react";

const Accordion = ({ item }: any) => {
  const [target, setTarget] = useState(false);
  const [clicked, setClicked] = useState(0);
  const descriptionRef = useRef<HTMLParagraphElement>(null);

  const toggleAccordion = () => {
    setClicked(item.id);
    setTarget(!target);
  };

  const getHeight = () => {
    if (descriptionRef.current && target) {
      return `${descriptionRef.current.scrollHeight}px`;
    }
    return "0px";
  };

  return (
    <li
      className={clicked === item.id && target ? "active" : ""}
      key={item.id}
      onClick={toggleAccordion}
    >
      <div>
        <h2>{item.title}</h2>
        <p
          ref={descriptionRef}
          style={{
            maxHeight: getHeight(),
            overflow: "hidden",
            transition: "max-height 0.3s ease",
          }}
        >
          {item.description}
        </p>
      </div>
    </li>
  );
};

export default Accordion;
