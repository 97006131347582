import { Link } from "react-router-dom";
import { ButtonCommon } from "../../../Layout/styled";
import {
  BenefitMobile,
  BenefitsBanner,
  BenefitsContainer,
  BenefitsHeader,
  BenefitsList,
} from "../Benefits/styled";
import TitleLanding from "../../../components/Landing/Title";
import { ContextProviderWrapper } from "../../../components/Context";
import { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const Started = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <BenefitsContainer id="started">
      <BenefitsHeader>
        <TitleLanding title="Get Started with TON Patrick" />
        <p>
          Just a few simple steps to participate in liquidity mining and <br />
          optimize your rewards.
        </p>
      </BenefitsHeader>
      <BenefitsBanner className="started" />
      {isDesktop ? (
        <BenefitsList>
          {startedData.map((item, index) => {
            return (
              <li key={index}>
                <figure>
                  <img
                    width={266}
                    height={172}
                    src={item.img}
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
                <h2>
                  {item.id}. {item.title}
                </h2>
                <p>{item.description}</p>
              </li>
            );
          })}
        </BenefitsList>
      ) : (
        <BenefitMobile>
          <Swiper spaceBetween={10} slidesPerView={2} loop={true}>
            {startedData.map((item, index) => (
              <SwiperSlide key={index}>
                <li>
                  <figure>
                    <img
                      width={266}
                      height={172}
                      src={item.img}
                      alt="icon"
                      loading="lazy"
                      className="img-modal"
                    />
                  </figure>
                  <h2>
                    {item.id}. {item.title}
                  </h2>
                  <p>{item.description}</p>
                </li>
              </SwiperSlide>
            ))}
          </Swiper>
        </BenefitMobile>
      )}
      <ButtonCommon>
        <Link to="/">
          Stake Now
          <img
            width={14}
            height={14}
            src="/img/Common/button_arrow.svg"
            alt="icon"
            loading="lazy"
          />
        </Link>
      </ButtonCommon>
    </BenefitsContainer>
  );
};

const startedData = [
  {
    id: 1,
    img: "/img/Landing/started_1.jpg",
    title: "Create a TON wallet",
    description:
      "Create a TON wallet to join the TON Patrick ecosystem. Use TON Wallet or any supporting TON Blockchain wallet.",
  },
  {
    id: 2,
    img: "/img/Landing/started_2.jpg",
    title: "Buy TON & TPT",
    description:
      "Buy TPT and TON tokens on supported exchanges to participate in the mining process.",
  },
  {
    id: 3,
    img: "/img/Landing/started_3.jpg",
    title: "Become a Nominator",
    description:
      "Stake TPT tokens to become a Nominator & participate in the mining system and start receiving rewards.",
  },
  {
    id: 4,
    img: "/img/Landing/started_4.jpg",
    title: "Track & Withdraw Rewards",
    description:
      "Track your mining progress and withdraw your rewards anytime from TON Patrick.",
  },
];

export default Started;
