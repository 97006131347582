import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    align-items: center;
    margin-bottom: 30px;
  }
`;
export const HeaderTitle = styled.div`
  h2 {
    font-family: Bahnschrift;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
  }
  p {
    font-size: 15px;
    font-weight: 500;
  }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 18px;
    }
  }
`;
export const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  figure {
    width: 21px;
    height: 21px;
  }
`;
export const HeaderNotification = styled.div`
  display: flex;
  gap: 10px;
  padding: 7px 12px;
  align-items: center;
  border-radius: 56px;
  background: rgba(68, 68, 68, 0.5);
  p {
    font-size: 12px;
    font-weight: 500;
  }
`;
export const HeaderNotificationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border-radius: 32px;
  background: #363636;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background-color: #ea2d2d;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 10px;
    right: 10px;
  }
`;
