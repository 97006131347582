import styled from "styled-components";
import {
  BlockContainer,
  BlockHeader,
} from "../../components/Common/Block/styled";
import { FormVisualContainer } from "../../components/Common/Form/Visual/styled";

export const WalletBody = styled.div`
  display: flex;
  gap: 8px;
  min-height: calc(100vh - 20px);
  @media screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    min-height: unset;
    ${FormVisualContainer} {
      min-height: 554px;
    }
  }
`;
export const WalletInfo = styled.div`
  width: 100%;
`;
export const WalletActions = styled.div`
  max-width: 400px;
  width: 100%;
  @media screen and (max-width: 1023px) {
    max-width: unset;
    width: 100%;
  }
`;
export const WalletBlocks = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  ${BlockContainer} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    min-height: 170px;
    ${BlockHeader} {
      margin-bottom: auto;
    }
  }
  @media screen and (max-width: 1023px) {
    ${BlockContainer} {
      min-height: 140px;
    }
  }
`;
export const WalletTable = styled.div``;
export const FormVisualIconDecor = styled.figure`
  position: absolute;
  right: 50px;
  top: 100px;
  @media screen and (max-width: 1023px) {
    right: 20px;
    top: 10px;
    img {
      width: 66px;
      height: 66px;
    }
  }
`;
export const FormInfo = styled.div`
  margin-bottom: 10px;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      color: #000;
    }
  }
`