import { useEffect, useState } from "react";
import BlockCommon from "../../components/Common/Block";
import FormVisual from "../../components/Common/Form/Visual";
import HeaderCommon from "../../components/Header";
import TableMining from "../../components/MiningTable";
import { FormVisualIconDecor } from "../Wallet/styled";
import Mining from "./Mining";
import { StakingBody, StakingInfo } from "./styled";
import { convertFixed } from "../../utils/convertNumber";

const Staking = () => {
  const [minerValue, setMinerValue] = useState(453);
  const [stakedValue, setStakedValue] = useState(782);
  const [valueLock, setValueLock] = useState(1021642);
  const getRandomValue = (min: any, max: any) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMinerValue((prev) => prev + getRandomValue(453, 1853));
      setStakedValue((prev) => prev + getRandomValue(782329, 1782329));
      setValueLock((prev) => prev + getRandomValue(1021642, 2521642));
    }, 24 * 60 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <HeaderCommon title="staking" />
      <StakingBody>
        <FormVisual
          content={
            <>
              <FormVisualIconDecor>
                <img
                  width={87}
                  height={93}
                  src="/img/Common/formVisual_decor_icon_2.png"
                  alt="icon"
                  loading="lazy"
                />
              </FormVisualIconDecor>
              <Mining />
            </>
          }
          textWarning="Notes"
        />
        <StakingInfo>
          <div>
            <h2>
              <img
                width={15}
                height={14}
                src="/img/Common/hint_2_icon.svg"
                alt="icon"
                loading="lazy"
              />
              POOL INFORMATION
            </h2>
            <BlockCommon
              icon="/img/Common/totalMiner_icon.svg"
              title="total miner"
              value={`${(minerValue)}`}
            />
            <BlockCommon
              icon="/img/Common/totalStaked_icon.svg"
              title="total staked"
              value={`$${convertFixed(stakedValue)}`}
            />
            <BlockCommon
              icon="/img/Common/totalLock_icon.svg"
              title="Total Value Lock"
              value={`$${convertFixed(valueLock)}`}
            />
          </div>
          <TableMining />
        </StakingInfo>
      </StakingBody>
    </div>
  );
};

export default Staking;
