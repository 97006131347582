import styled from "styled-components";

export const LandingContainer = styled.div`
  background-color: #121212;
`
export const LandingWrapper = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  overflow: hidden;
  @media (max-width: 1023px) {
    padding: 0 15px;
    width: unset;
  }
`
