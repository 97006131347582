import styled from "styled-components";

export const DashboardBody = styled.div`
  padding: 4px;
  display: flex;
  gap: 4px;
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    padding: 0;
    display: block;
    height: auto;
  }
`;
export const DashboardBodyWrapper = styled.div`
  margin-left: auto;
  width: calc(100% - 328px);
  padding: 18px 14px;
  border-radius: 8px;
  background: rgba(68, 68, 68, 0.5);
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: calc(100% - 135px);
  }
  @media screen and (max-width: 767px) {
    width: auto;
    padding: 100px 20px 40px;
    background-color: transparent;
  }
`;
