import { Link } from "react-router-dom";
import TitleLanding from "../../../components/Landing/Title";
import { ButtonCommon } from "../../../Layout/styled";
import {
  BenefitMobile,
  BenefitsBanner,
  BenefitsContainer,
  BenefitsHeader,
  BenefitsList,
} from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ContextProviderWrapper } from "../../../components/Context";
import { useContext } from "react";

const Benefits = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <BenefitsContainer id="benefits">
      <BenefitsHeader>
        <TitleLanding title="TON Patrick Benefits" />
        <p>
          Optimize mining performance and receive attractive rewards with
          <br /> the decentralized platform.
        </p>
      </BenefitsHeader>
      <BenefitsBanner />
      {isDesktop ? (
        <BenefitsList>
          {benefitsData.map((item, index) => {
            return (
              <li key={index}>
                <figure>
                  <img
                    width={266}
                    height={172}
                    src={item.img}
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </li>
            );
          })}
        </BenefitsList>
      ) : (
        <BenefitMobile>
          <Swiper
            spaceBetween={10}
            slidesPerView={2}
            loop={true}
          >
            {benefitsData.map((item, index) => (
              <SwiperSlide>
                <figure>
                  <img
                    width={266}
                    height={172}
                    src={item.img}
                    alt="icon"
                    loading="lazy"
                    className="img-modal"
                  />
                </figure>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        </BenefitMobile>
      )}
      <ButtonCommon className="blue">
        <Link to="/">
          Launch App
          <img
            width={20}
            height={20}
            src="/img/Common/arrow_white.svg"
            alt="icon"
            loading="lazy"
          />
        </Link>
      </ButtonCommon>
    </BenefitsContainer>
  );
};

const benefitsData = [
  {
    id: 1,
    img: "/img/Landing/benefits_1.jpg",
    title: "Mining Optimization",
    description:
      "TON Patrick utilizes advanced algorithms on the TON Blockchain for efficient mining and stable rewards.",
  },
  {
    id: 2,
    img: "/img/Landing/benefits_2.jpg",
    title: "Easy to Participate",
    description:
      "Users can participate in the mining process without investing in complex hardware infrastructure.",
  },
  {
    id: 3,
    img: "/img/Landing/benefits_3.jpg",
    title: "Security and Transparency",
    description:
      "TON Patrick on TON Blockchain ensures secure, transparent mining.",
  },
  {
    id: 4,
    img: "/img/Landing/benefits_4.jpg",
    title: "Flexible and Fair",
    description:
      "TON Patrick's reward distribution system is designed to be flexible based on users' actual contributions.",
  },
];

export default Benefits;
