import { TitleContainer } from "./styled";

const TitleCommon = ({ title, desc }: any) => {
  return (
    <TitleContainer>
      <h2>{title}</h2>
    </TitleContainer>
  );
};

export default TitleCommon;
