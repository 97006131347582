import styled from "styled-components";

export const ProgressContainer = styled.div`
  position: relative;
`;
export const ProgressWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  background: #3a3a3a;
  height: 24px;
  overflow: hidden;
`;
export const ProgressLine = styled.div`
  height: 24px;
  transition: all .55s linear;
`;
export const ProgressContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  border-radius: 5px;
  background: rgba(150, 163, 255, 0.2);
  backdrop-filter: blur(2px);
  font-size: 11px;
  font-weight: 600;
  color: #fff;
`;
