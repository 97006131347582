import styled from "styled-components";

export const ButtonCommon = styled.button`
  border-radius: 67px;
  background: #fff;
  padding: 0;
  border: none;
  outline: none;
  transition: all .15s linear;
  cursor: pointer;
  &.blue {
    background: #007fff;
    a,
    p {
      color: #fff;
    }
  }
  &.warning {
    background: rgba(255, 94, 94, 0.17);
    a,
    p {
      color: #FF5454;
    }
  }
  &.success {
    background-color: #003BFF;
    a,
    p {
      color: #fff;
    }
  }
  a,
  p {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    justify-content: center;
    gap: 8px;
    color: #292929;
    font-size: 15px;
    font-weight: 700;
  }
  img {
    position: relative;
    top: 1px;
  }
  &:hover {
    opacity: .7;
  }
`;
export const ButtonAction = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  gap: 5px;
  justify-content: center;
  outline: none;
  border: none;
  width: max-content;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 67px;
  transition: all 0.15s linear;
  font-size: 13px;
  font-weight: 600;
  &:hover {
    opacity: 0.7;
  }
  & > figure {
    width: 16px;
    height: 16px;
  }
  &.error {
    background: rgba(255, 221, 221, 0.9);
    color: #e00000;
  }
  &.warning {
    background: rgba(255, 91, 45, 0.5);
    color: #e5e5e5;
  }
`;
export const TokenValue = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  figure {
    width: 21px;
    height: 20px;
  }
`;
export const ButtonCopy = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(142, 142, 142, 0.2);
  background: rgba(248, 248, 248, 0.05);
  background-blend-mode: luminosity;
  transition: all 0.15s linear;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
export const AddressCopy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  figure {
    width: 14px;
    height: 14px;
    padding: 5px;
    border-radius: 6px;
    background: #5c5c5c;
  }
  p {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
export const TagStatus = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px rgba(255, 255, 255, 0.4);
  background: rgba(248, 248, 248, 0.05);
  background-blend-mode: luminosity;
  p {
    font-size: 13px;
    font-weight: 600;
  }
  figure {
    width: 15px;
    height: 16px;
  }
  &.success {
    color: #7aff65;
  }
  &.error {
    color: #ff6565;
  }
  &.pending {
    color: #f5a458;
  }
`;
