import styled from "styled-components";
import { TitlePage } from "../../../components/Landing/Title/styled";
import { ButtonCommon } from "../../../Layout/styled";

export const BenefitsContainer = styled.div`
  padding-top: 100px;
  ${ButtonCommon} {
    display: block;
    margin: 0 auto;
  }
`;
export const BenefitsHeader = styled.div`
  margin-bottom: 20px;
  p {
    position: relative;
    padding-left: 15px;
    color: rgba(255, 255, 255, 0.38);
    font-size: 16px;
    font-weight: 500;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      width: 9px;
      height: 9px;
      background-color: #06f;
      border-radius: 50%;
    }
  }
  ${TitlePage} {
    margin-bottom: 8px;
  }
`;
export const BenefitsBanner = styled.div`
  width: 100%;
  min-height: 294px;
  background: url("/img/Landing/benefits_banner.jpg") no-repeat center / cover;
  border-radius: 16px;
  margin-bottom: 15px;
  &.started {
    background-image: url("/img/Landing/started_banner.jpg");
  }
  @media screen and (max-width: 1023px) {
    &.started {
    background-image: url("/img/Landing/started_banner_sp.png");
  }
  }
`;
export const BenefitsList = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  & > li {
    width: calc(25% - 15px);
    figure {
      width: 266px;
      height: auto;
      margin-bottom: 15px;
      border-radius: 16px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    h2 {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.43);
      line-height: 110%;
    }
  }
  @media (max-width: 1023px) {
    & > li {
      width: 100%;
    }
  }
`;
export const BenefitMobile = styled.div`
  margin-right: -36%;
  margin-bottom: 20px;
  .img-modal {
    width: 100%;
    border-radius: 16px;
  }
  h2 {
    margin-top: 5px;
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  p {
    color: rgba(255, 255, 255, 0.43);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.15px;
  }
  @media screen and (max-width: 1023px) {
    .img-modal {
      height: auto;
    }
  }
`;
