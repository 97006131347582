import styled from "styled-components";
import { ButtonCommon } from "../../../../Layout/styled";

export const ContentWarningContainer = styled.div``;
export const WarningHeader = styled.div`
  margin-bottom: 5px;
  figure {
    width: 82px;
    height: 80px;
    margin: 0 auto 5px;
  }
  h2 {
    color: #fff;
    text-align: center;
    font-size: 21px;
    font-weight: bold;
  }
`;
export const WarningBody = styled.div`
  margin-bottom: 20px;
  p {
    color: #3e3e3e;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }
`;
export const WarningButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${ButtonCommon} {
    width: 50%;
  }
`;
