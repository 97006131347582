import { Select } from "antd";
import { SelectContainer } from "./styled";

const { Option } = Select;

const SelectCommon = ({ data, onChange, defaultValue, suffixIcon, disabled }: any) => {
  return (
    <SelectContainer>
      <Select
        disabled={disabled}
        suffixIcon={suffixIcon ? suffixIcon :<img src="./img/Common/arrow_select.svg" alt="arrow" />}
        placeholder="Please select value"
        defaultValue={defaultValue ? defaultValue : data[0].title}
        style={{ width: "100%" }}
        onChange={onChange}
        virtual={false}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {data &&
          data.map((item: any, index: number) => (
            <Option key={index} value={item.title} label={item.title}>
              <div>
                {item.img === "" ? (
                  ""
                ) : (
                  <img width={20} height={20} src={item.img} alt="icon" loading="lazy" />
                )}
                <p>{item.title}</p>
              </div>
            </Option>
          ))}
      </Select>
    </SelectContainer>
  );
};

export default SelectCommon;
