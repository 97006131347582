import styled from "styled-components";

export const TitlePage = styled.h2`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    font-size: 23px;
    align-items: flex-end;
  }
`;
