import { Link } from "react-router-dom";
import {
  BgMenu,
  MenuAddress,
  MenuContent,
  MenuMobileContainer,
  MenuSelect,
  MenuSideBar,
} from "./styled";
import { useEffect, useState } from "react";
import ConnectWallet from "../Sidebar/ConnectWallet";
import Navigation from "../Sidebar/Navigation";
import { shortenAddress } from "../../utils/addressUser";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const MenuMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  useEffect(()=>{
    const body = document.getElementById("root");
    if(body && menuOpen){
      // @ts-ignore
      body.style.height = "100vh";
      body.style.overflow="hidden";
    }else{
      // @ts-ignore
      body.style.height = "auto";
    }
  },[menuOpen])
  return (
    <>
      <BgMenu className={menuOpen ? "open" : ""} />
      <MenuMobileContainer>
        <MenuSideBar>
          <Link to="/">
            <figure>
              <img src="/img/Common/logo_frame.svg" alt="icon" loading="lazy" />
            </figure>
          </Link>
          {/* {address ? ( */}
            <MenuSelect
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              {menuOpen ? (
                <>
                  <img
                    className="icon-menu-active"
                    src="/img/Common/sp/icn_header_09.png"
                    alt="icon"
                    width={41}
                    height={41}
                    loading="lazy"
                  />
                </>
              ) : (
                <>
                  <img
                    className="icon-menu-normal"
                    src="/img/Common/sp/icn_header_08.png"
                    alt="icon"
                    width={41}
                    height={41}
                    loading="lazy"
                  />
                </>
              )}
            </MenuSelect>
          {/* ) : (
            <></>
          )} */}
          <MenuAddress
            onClick={() => {
              if (open) {
                open();
              }
            }}
          >
            {address ? shortenAddress(address, 3) : "Connect wallet"}
          </MenuAddress>
        </MenuSideBar>
      </MenuMobileContainer>
        <MenuContent className={menuOpen ? "open" : ""}>
          <ConnectWallet />
          <Navigation setMenuOpen={setMenuOpen} />
        </MenuContent>
    </>
  );
};

export default MenuMobile;
