import { useSelector } from "react-redux";
import ProgressCommon from "../Progress";
import {
  CardContainer,
  CardCurrentRank,
  CardProgress,
  CardWrapper,
  ProgressContent,
} from "./styled";
import { convertFixed } from "../../../utils/convertNumber";

const CardCommon = ({ icon, level, className, backgroundColor }: any) => {
  const { userProfile } = useSelector((state: any) => state.user);

  const rankingData = [
    {
      id: 1,
      name: "Miner",
      ranking: 1,
      member: 0,
      investRequired: 100,
      teamInvest: 0,
    },
    {
      id: 2,
      name: "harvester",
      ranking: 2,
      member: 2,
      investRequired: 200,
      teamInvest: 0,
    },
    {
      id: 3,
      name: "guardian",
      ranking: 3,
      member: 3,
      investRequired: 300,
      teamInvest: 2000,
    },
    {
      id: 4,
      name: "delegate",
      ranking: 4,
      member: 4,
      investRequired: 500,
      teamInvest: 20000,
    },
    {
      id: 5,
      name: "nominator",
      ranking: 5,
      member: 5,
      investRequired: 1000,
      teamInvest: 50000,
    },
    {
      id: 6,
      name: "validator",
      ranking: 6,
      member: 6,
      investRequired: 2000,
      teamInvest: 200000,
    },
    {
      id: 7,
      name: "elite validator",
      ranking: 7,
      member: 7,
      investRequired: 5000,
      teamInvest: 1000000,
    },
  ];

  const f1Percentage =
    userProfile?.rank > 0 &&
    Math.min(
      (Number(userProfile?.totalF1) /
        Number(rankingData[userProfile?.rank]?.member)) *
        100,
      100
    );
  const investedPercentage = Math.min(
    (Number(userProfile?.totalAmountPackage) /
      Number(rankingData[userProfile?.rank]?.investRequired)) *
      100,
    100
  );
  const teamInvestPercentage =
    userProfile?.rank > 0 &&
    Math.min(
      (Number(userProfile?.totalSale) /
        Number(rankingData[userProfile?.rank]?.teamInvest)) *
        100,
      100
    ) * 100;

  return (
    <CardWrapper>
      <CardContainer className={className}>
        <figure>
          <img width={146} height={94} src={icon} alt="icon" loading="lazy" />
        </figure>
        <div>
          <p>{level}</p>
          <span>Next rank</span>
        </div>
        <CardProgress>
          <div>
            <ProgressCommon
              content={
                userProfile?.totalF1
                  ? `${userProfile?.totalF1} / ${
                      rankingData[userProfile?.rank]?.member
                    }`
                  : 0
              }
              progress={userProfile?.rank > 0 ? f1Percentage : 0}
              backgroundColor={backgroundColor[0]}
            />
            <ProgressContent>
              <p>Miner Member</p>
            </ProgressContent>
          </div>
          <div>
            <ProgressCommon
              content={
                userProfile?.totalAmountPackage
                  ? `$${convertFixed(Number(userProfile?.totalAmountPackage))}`
                  : 0
              }
              progress={investedPercentage}
              backgroundColor={backgroundColor[1]}
            />
            <ProgressContent>
              <p>Your Invest</p>
            </ProgressContent>
          </div>
          <div>
            <ProgressCommon
              content={
                userProfile?.totalSale
                  ? `$${convertFixed(Number(userProfile?.totalSale))}`
                  : 0
              }
              progress={userProfile?.rank > 0 ? teamInvestPercentage : 0}
              backgroundColor={backgroundColor[2]}
            />
            <ProgressContent>
              <p>Team Invest</p>
            </ProgressContent>
          </div>
        </CardProgress>
      </CardContainer>
      <CardCurrentRank>
        <figure>
          <img
            width={14}
            height={14}
            src="/img/Common/hint_icon.svg"
            alt="icon"
            loading="lazy"
          />
        </figure>
        <p>Reach all conditions above to level up</p>
      </CardCurrentRank>
    </CardWrapper>
  );
};

export default CardCommon;
