import { useEffect, useState } from "react";
import { InfoContainer, InfoContainerWrapper, InfoList } from "./styled";
import CountUp from 'react-countup';

const Info = () => {
  const [infoValues, setInfoValues] = useState(infoData);


  // Function to randomly increase one value at a time
  const randomizeSingleValue = () => {
    setInfoValues((prevData) => {
      const randomIndex = Math.floor(Math.random() * prevData.length);
      const updatedData = prevData.map((item, index) => {
        if (index === randomIndex) {
          return {
            ...item,
            value: item.value + Math.floor(Math.random() * 10),
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      randomizeSingleValue();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <InfoContainer>
      <InfoContainerWrapper>
        <InfoList>
          {infoValues.map((item, index) => {
            return (
              <li key={index}>
                <p><CountUp end={item.value} /></p>
                <p>{item.name}</p>
              </li>
            );
          })}
        </InfoList>
      </InfoContainerWrapper>
    </InfoContainer>
  );
};

const infoData = [
  {
    id: 1,
    name: "Total Nominator",
    value: 32232,
  },
  {
    id: 2,
    name: "Total TPT Locked",
    value: 94432128,
  },
  {
    id: 3,
    name: "Total TON Locked",
    value: 243732,
  },
  {
    id: 4,
    name: "Total Value Locked",
    value: 2231387,
  },
];

export default Info;
