import styled from "styled-components";

export const SidebarContainer = styled.div`
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  max-width: 272px;
  min-height: calc(100vh - 36px);
  width: 100%;
  padding: 14px;
  border-radius: 8px;
  background: rgba(68, 68, 68, 0.5);
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 80px;
  }
`;
export const SidebarLogo = styled.div`
  margin-bottom: 40px;
  a {
    display: block;
    width: max-content;
  }
`;
export const SidebarList = styled.ul`
  padding-bottom: 50px;
  & > li {
    &:not(:last-child) {
      margin-bottom: 3px;
    }
    a {
      position: relative;
      padding: 12px 14px;
      display: flex;
      align-items: center;
      overflow: hidden;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 8px;
        box-shadow: 0px -3px 4px 0px rgba(0, 40, 104, 0.35) inset,
          0px 4px 8.9px 0px rgba(203, 228, 255, 0.75) inset;
        background: linear-gradient(90deg, #4891ff 0%, #fff 100%);
        opacity: 0;
        transition: all 0.2s linear;
      }
      div {
        position: relative;
        z-index: 5;
        display: flex;
        align-items: center;
        gap: 10px;
        figure {
          width: 24px;
          height: 24px;
          filter: grayscale(1);
          transition: all 0.15s linear;
        }
        p {
          font-size: 15px;
          color: #878ea0;
        }
      }
      &:hover,
      &.active {
        div {
          p {
            color: #fff;
          }
          figure {
            filter: grayscale(0);
          }
        }
        &::before {
          opacity: 1;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    & > li {
      a {
        justify-content: center;
        & > div {
          p {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    & > li {
      a {
        & > div {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    & > li {
      a {
        justify-content: center;
        width: max-content;
        margin: 0 auto;
        & > div {
          p {
            font-weight: bold;
          }
        }
        &:hover,
        &.active {
          div {
            p {
              background: var(
                --Blue-Gra,
                linear-gradient(180deg, #83b5ff 18.88%, #e2f3ff 100%)
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            figure {
              filter: grayscale(0);
            }
          }
          &::before {
            opacity: 0;
          }
        }
      }
    }
  }
`;
export const SidebarInner = styled.div``;
export const SidebarCW = styled.div`
  border-radius: 16px;
  background: #2c2c2c;
  padding: 16px;
  margin-top: auto;
  & > figure {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 168px;
    border-radius: 12px;
    background: #242424;
    margin-bottom: 6px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: url("/img/Sidebar/sidebar_cw_dot.png") no-repeat center / 100%
        auto;
      width: calc(100% - 10px);
      height: 8px;
      left: 5px;
    }
    &::before {
      top: 10px;
    }
    &::after {
      bottom: 10px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 0;
    background-color: transparent;
  }
  @media screen and (max-width: 767px) {
    max-width: 300px;
    margin: 0 auto 20px;
  }
  .animation-scale {
    animation: animationScale 2s linear infinite;
  }
  @keyframes animationScale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;
export const SidebarCWButton = styled.button`
  border-radius: 8px;
  background: #fff;
  display: flex;
  width: 100%;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  &.error {
    border-radius: 8px;
    background: rgba(255, 94, 94, 0.17);
    color: #ff5454;
  }
`;
export const WalletConnectFrame = styled.div`
  margin-bottom: 8px;
  & > figure {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
`;
export const FrameWallet = styled.div`
  background-color: #6c95ff;
  padding: 15px 10px 10px;
  margin-top: -75px;
  position: relative;
  border-radius: 0 0 20px 20px;
  & > p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    display: none;
  }
`;
export const WalletAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #95c1ff;
  padding: 2px 8px 2px 2px;
  & > p {
    width: calc(100% - 20px);
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 7px;
    background: #70a0fb;
  }
  & > figure {
    cursor: pointer;
    transition: all 0.15s linear;
    &:hover {
      opacity: 0.7;
    }
  }
`;
