import { useContext } from "react";
import Notification from "./Notification";
import {
  HeaderAction,
  HeaderContainer,
  HeaderNotification,
  HeaderTitle,
} from "./styled";
import { ContextProviderWrapper } from "../Context";

const HeaderCommon = ({ title, description }: any) => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  return (
    <HeaderContainer>
      <HeaderTitle>
        <h2>{title}</h2>
        {!isMobile && <p>{description}</p>}
      </HeaderTitle>
      <HeaderAction>
        {!isMobile && <Notification />}
      </HeaderAction>
    </HeaderContainer>
  );
};

export default HeaderCommon;
