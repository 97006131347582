import styled from "styled-components";
import { TitlePage } from "../../../components/Landing/Title/styled";

export const FaqsContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  ${TitlePage} {
    margin-bottom: 24px;
    text-align: center;
    flex-direction: column;
    gap: 0;
    align-items: center;
    h3 {
      color: #408cff;
      font-size: 32px;
      font-weight: 600;
      text-transform: capitalize;
      display: block;
    }
  }
`;
export const FaqsList = styled.ul`
  & > li {
    position: relative;
    padding: 16px 12px;
    border-radius: 8px;
    background: #292929;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    & > div {
      padding-left: 25px;
    }
    h2 {
      position: relative;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      &::before,
      &::after {
        content: "";
        position: absolute;
        pointer-events: none;
        background-color: #fff;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.1s linear;
      }
      &::before {
        width: 12px;
        height: 2px;
        left: -20px;
      }
      &::after {
        width: 2px;
        height: 12px;
        left: -14px;
        margin-left: -1px;
      }
    }
    p {
      color: #a5a5a5;
      font-size: 14px;
      font-weight: 500;
      height: 0;
      overflow: hidden;
      transition: all 0.15s linear;
    }
    &.active {
      p {
        margin-top: 8px;
        height: 40px;
      }
      h2 {
        &::after {
          transform: rotate(90deg);
          margin-top: -6px;
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    & > li {
      &.active {
        p {
          height: 90px;
        }
      }
    }
  }
`;
