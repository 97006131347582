import { useEffect, useState } from "react";
import BlockCommon from "../../components/Common/Block";
import HeaderCommon from "../../components/Header";
import {
  LevelDetailList,
  LevelDetailTitle,
  NetworkBody,
  NetworkIcon,
  NetworkInfo,
  NetworkLevel,
  NetworkLevelData,
  NetworkLevelDetail,
  NetworkLevelList,
  NetworkQR,
  QRQuote,
  QRWrapper,
} from "./styled";
import { PatrickAPI } from "../../services/api";
import { useAccount } from "wagmi";
import { shortenAddress } from "../../utils/addressUser";
import { convertFixed } from "../../utils/convertNumber";
import { FormBalance } from "../Wallet/Transfer/styled";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";

const Network = () => {
  const { address } = useAccount();
  const [level, setLevel] = useState(1);
  const [network, setNetwork] = useState<any>([]);
  const [networkList, setNetworkList] = useState([
    {
      id: 1,
      iconLevel: "/img/Network/network_level_1.png",
      member: "0",
      levelCommission: "0",
      receiveCommission: "50",
      totalStaked: "0",
    },
    {
      id: 2,
      iconLevel: "/img/Network/network_level_2.png",
      member: "0",
      levelCommission: "0",
      receiveCommission: "50",
      totalStaked: "0",
    },
    {
      id: 3,
      iconLevel: "/img/Network/network_level_3.png",
      member: "0",
      levelCommission: "0",
      receiveCommission: "50",
      totalStaked: "0",
    },
    {
      id: 4,
      iconLevel: "/img/Network/network_level_4.png",
      member: "0",
      levelCommission: "0",
      receiveCommission: "50",
      totalStaked: "0",
    },
    {
      id: 5,
      iconLevel: "/img/Network/network_level_5.png",
      member: "0",
      levelCommission: "0",
      receiveCommission: "50",
      totalStaked: "0",
    },
    {
      id: 6,
      iconLevel: "/img/Network/network_level_6.png",
      member: "0",
      levelCommission: "0",
      receiveCommission: "50",
      totalStaked: "0",
    },
  ]);
  const [networkInfo, setNetworkInfo] = useState({
    totalMember: 0,
    totalRevenue: 0,
    totalCommission: 0,
  });
  const handleGetNetwork = async () => {
    const param = {
      address: address,
      level: level,
      page: 1,
      limit: 100,
    };
    try {
      const res = await PatrickAPI.getNetwork(param);
      if (res.status === 200) {
        setNetwork(res.data.data.networks);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (level && address) {
      handleGetNetwork();
    }
  }, [level, address]);

  const handleRevenueNetwork = async () => {
    try {
      const res = await PatrickAPI.getRevenueNetwork(address);
      if (res.status === 200) {
        setNetworkInfo((prev) =>({
          ...prev,
          totalRevenue: res?.data?.data?.totalSale,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMemberNetwork = async () => {
    try {
      const res = await PatrickAPI.getMemberNetwork(address);
      if (res.status === 200) {
        setNetworkInfo((prev) => ({
          ...prev,
          totalMember: res?.data?.data?.totalMember,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommissionNetwork = async () => {
    try {
      const res = await PatrickAPI.getCommissionNetwork(address);
      if (res.status === 200) {
        setNetworkInfo((prev) => ({
          ...prev,
          totalCommission: res?.data?.data?.totalCommission,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalNetwork = async () => {
    const levelPromises = [];
    for (let i = 1; i <= 6; i++) {
      const param = {
        address: address,
        level: i,
      };
      const request = PatrickAPI.getTotalNetwork(param)
        .then((res) => {
          if (res.status === 200) {
            return res.data.data;
          }
        })
        .catch((error) => {
          console.log(`Error fetching data for level ${i}:`, error);
          return null;
        });
      levelPromises.push(request);
    }
    try {
      const networkData = await Promise.all(levelPromises);
      const updatedNetworkList = networkList.map((item, index) => {
        const networkLevelData = networkData[index];
        return {
          ...item,
          member: networkLevelData?.totalReferral || "0",
          totalStaked: networkLevelData?.totalMining || "0",
        };
      });
      setNetworkList(updatedNetworkList);
    } catch (error) {
      console.log("Error fetching network data:", error);
    }
  };

  useEffect(() => {
    if (address) {
      handleRevenueNetwork();
      handleMemberNetwork();
      handleCommissionNetwork();
      getTotalNetwork();
    }
  }, [address]);

  const handleChangeLevel = (level: any) => {
    switch (level) {
      case 1:
        return (
          <NetworkLevelData>
            <figure>
              <img
                width={15}
                height={15}
                src="/img/Common/level_1.png"
                alt="icon"
                loading="lazy"
              />
            </figure>
            <p>Miner</p>
          </NetworkLevelData>
        );
      case 2:
        return (
          <NetworkLevelData>
            <figure>
              <img
                width={15}
                height={15}
                src="/img/Common/level_2.png"
                alt="icon"
                loading="lazy"
              />
            </figure>
            <p>Harvester</p>
          </NetworkLevelData>
        );
      case 3:
        return (
          <NetworkLevelData>
            <figure>
              <img
                width={15}
                height={15}
                src="/img/Common/level_3.png"
                alt="icon"
                loading="lazy"
              />
            </figure>
            <p>Guardian</p>
          </NetworkLevelData>
        );
      case 4:
        return (
          <NetworkLevelData>
            <figure>
              <img
                width={15}
                height={15}
                src="/img/Common/level_4.png"
                alt="icon"
                loading="lazy"
              />
            </figure>
            <p>Delegate</p>
          </NetworkLevelData>
        );
      case 5:
        return (
          <NetworkLevelData>
            <figure>
              <img
                width={15}
                height={15}
                src="/img/Common/level_5.png"
                alt="icon"
                loading="lazy"
              />
            </figure>
            <p>Nominator</p>
          </NetworkLevelData>
        );
      case 6:
        return (
          <NetworkLevelData>
            <figure>
              <img
                width={15}
                height={15}
                src="/img/Common/level_6.png"
                alt="icon"
                loading="lazy"
              />
            </figure>
            <p>Validator</p>
          </NetworkLevelData>
        );
      case 7:
        return (
          <NetworkLevelData>
            <figure>
              <img
                width={15}
                height={15}
                src="/img/Common/level_7.png"
                alt="icon"
                loading="lazy"
              />
            </figure>
            <p>Elite Validator</p>
          </NetworkLevelData>
        );
      default:
        return (
          <NetworkLevelData>
            <figure>
              <img
                width={15}
                height={15}
                src="/img/Common/level_0.png"
                alt="icon"
                loading="lazy"
              />
            </figure>
            <p>Member</p>
          </NetworkLevelData>
        );
    }
  };

  return (
    <div>
      <HeaderCommon title="network" />
      <NetworkBody>
        <BlockCommon
          icon="/img/Common/totalMember_icon.svg"
          title="total member"
          value={`${networkInfo?.totalMember || 0}`}
        />
        <BlockCommon
          icon="/img/Common/totalMining_icon.svg"
          title="Total Revenue"
          value={`${convertFixed(networkInfo?.totalRevenue || 0)}`}
        />
        <BlockCommon
          icon="/img/Common/totalCommission_icon.svg"
          title="total commission"
          value={`${convertFixed(networkInfo?.totalCommission) || 0}`}
        />
        <NetworkQR>
          <QRWrapper>
            <QRCode
              style={{
                height: "auto",
                maxWidth: "100%",
                width: "100%",
                borderRadius: "7px",
              }}
              value={`https://tonpatrick.io/ref/${address}`}
            />
          </QRWrapper>
          <FormBalance>
            <p>
              {!address
                ? "Please connect wallet"
                : shortenAddress(`https://tonpatrick.io/ref/${address}`, 12)}
            </p>
            {address && (
              <CopyToClipboard
                text={`https://tonpatrick.io/ref/${address}`}
                onCopy={() => toast.success("Coppied !")}
              >
                <figure>
                  <img
                    width={26}
                    height={26}
                    src="/img/Common/copy_white.png"
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
              </CopyToClipboard>
            )}
          </FormBalance>
          <QRQuote>
            <p>Copy/Scan QR & share to your friends</p>
          </QRQuote>
        </NetworkQR>
      </NetworkBody>
      <NetworkLevel>
        <NetworkLevelList>
          {networkList.map((item, index) => {
            return (
              <li
                className={item.id === level ? "active" : ""}
                onClick={() => {
                  setLevel(item.id);
                }}
                key={index}
              >
                <figure>
                  <img
                    width={92}
                    height={118}
                    src={item.iconLevel}
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
                <NetworkIcon>
                  <img
                    width={20}
                    height={20}
                    src="/img/Network/network_icon.svg"
                    alt="icon"
                    loading="lazy"
                  />
                </NetworkIcon>
                <NetworkInfo>
                  <div>
                    <p>Member</p>
                    <span>{item.member}</span>
                  </div>
                  <div>
                    <p>Total Mining</p>
                    <span>${item.totalStaked}</span>
                  </div>
                </NetworkInfo>
              </li>
            );
          })}
        </NetworkLevelList>
        <NetworkLevelDetail>
          <LevelDetailTitle>
            <h2>Level 0{level}</h2>
            <p>{network?.length > 0 ? network?.length : 0}</p>
          </LevelDetailTitle>
          <LevelDetailList className={network?.length > 0 ? "" : "no-data"}>
            {network?.length > 0 ? (
              <>
                {network?.map((item: any, index: any) => {
                  return (
                    <li key={index}>
                      <div>
                        <p>Address</p>
                        <p>{shortenAddress(item.address, 4)}</p>
                      </div>
                      <div>
                        <p>Rank</p>
                        <p>{handleChangeLevel(item.rank)}</p>
                      </div>
                      <div>
                        <p>Total Mining</p>
                        <p>${item.totalMining}</p>
                      </div>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                <li>Don't have any data here</li>
              </>
            )}
          </LevelDetailList>
        </NetworkLevelDetail>
      </NetworkLevel>
    </div>
  );
};

export default Network;
