import styled from "styled-components";
import {
  ProgressContainer,
  ProgressLine,
  ProgressWrapper,
} from "../Progress/styled";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &.hide {
    flex-direction: unset;
  }
`;
export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 306px;
  min-height: 240px;
  padding: 20px 10px;
  border-radius: 18px 18px 0 0;
  z-index: 5;
  overflow: hidden;
  &.level_0 {
    background: linear-gradient(180deg, #CACACA 18.88%, #E2F3FF 100%);
  }
  &.level_1 {
    background: linear-gradient(180deg, #98ff83 18.88%, #e2f3ff 100%);
  }
  &.level_2 {
    background: linear-gradient(180deg, #83e0ff 18.88%, #e2f3ff 100%);
  }
  &.level_3 {
    background: linear-gradient(180deg, #83b5ff 18.88%, #e2f3ff 100%);
  }
  &.level_4 {
    background: linear-gradient(180deg, #b983ff 18.88%, #e2f3ff 100%);
  }
  &.level_5 {
    background: linear-gradient(180deg, #ffd283 18.88%, #e2f3ff 100%);
  }
  &.level_6 {
    background: linear-gradient(180deg, #ff83bb 18.88%, #e2f3ff 100%);
  }
  &.level_7 {
    background: linear-gradient(180deg, #ff4f52 18.88%, #e2f3ff 100%);
  }
  &::before {
    content: "";
    position: absolute;
    pointer-events: none;
    background: url("/img/Common/bg_card.png") no-repeat center / cover;
    width: 100%;
    height: 110%;
    left: 0;
    top: -10px;
    mix-blend-mode: overlay;
    z-index: 5;
  }
  & > figure {
    position: relative;
    z-index: 5;
    width: 146px;
    height: 94px;
    margin: 0 auto 10px;
  }
  & > div {
    text-align: center;
    &:nth-child(2) {
      margin-bottom: auto;
      p {
        color: #4b4b4b;
        font-size: 18px;
        font-weight: bold;
      }
      span {
        color: #4b4b4b;
        font-size: 13px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: auto;
    min-height: 270px;
  }
`;
export const CardCurrentRank = styled.div`
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 30px 10px 10px;
  margin-top: -20px;
  background: #d3e7ff;
  border-radius: 8px;
  & > figure {
    width: 14px;
    height: 16px;
  }
  & > p {
    font-size: 12px;
    font-weight: 500;
    color: #277eff;
  }
  ${ProgressContainer} {
    width: 115px;
    ${ProgressWrapper},
    ${ProgressLine} {
      height: 10px;
    }
    ${ProgressWrapper} {
      background-color: #fff;
    }
    ${ProgressLine} {
      background-color: #277EFF !important;
    }
  }
`;
export const CardProgress = styled.div`
  & > div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  ${ProgressContainer} {
    width: 65%;
    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }
`;
export const ProgressContent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #515151;
  text-align: right;
  & > figure {
    width: 14px;
    height: 14px;
  }
  p {
    font-size: 12px;
  }
`;
