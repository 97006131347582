import styled from "styled-components";

export const FormTabs = styled.div`
  position: relative;
  z-index: 5;
  border-radius: 8px;
  background: rgba(40, 40, 42, 0.19);
  display: flex;
  width: max-content;
  margin: 0 auto;
  overflow: hidden;
  & > div {
    padding: 10px 24px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: transparent;
    transition: all 0.15s linear;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: #fff;
      color: #3c8dff;
    }
  }
`;
