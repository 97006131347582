import { TitlePage } from "./styled";

const TitleLanding = ({ title, subContent }: any) => {
  return (
    <TitlePage>
      {title}
      {subContent}
    </TitlePage>
  );
};

export default TitleLanding;
