import { useEffect, useState } from "react";
import InputCommon from "../../../components/Common/Input";
import {
  ButtonForm,
  FormBalance,
  FormInputs,
  PerList,
  TransferContainer,
  TransferHeader,
} from "../../Wallet/Transfer/styled";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import { convertFixed, convertToWei } from "../../../utils/convertNumber";
import web3 from "web3";
import { abiMining, abiUSDT } from "../../../contract/Pair";
import { addressMining, addressUSDT } from "../../../contract/AddressContract";
import { useAccount } from "wagmi";
import toast from "react-hot-toast";
import getTokenInfo from "../../../utils/checkInfoByAccount";
import { shortenAddress } from "../../../utils/addressUser";
import { TransactionHash } from "../styled";
import { Link } from "react-router-dom";
import { IsLoadingRedux } from "../../../redux/slices/user";
import { dispatch } from "../../../redux/store";
import { Spin } from "antd";

declare const window: Window & typeof globalThis & { ethereum: any };

const Mining = () => {
  const Web3 = new web3(window.ethereum);
  const [per, setPer] = useState(0);
  const { usdtBalance } = useSelector((state: any) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [valueInput, setValueInput] = useState(0);
  const { address } = useAccount();
  const [allowance, setAllowance] = useState<any>("");
  // Approve
  const getAllowance = async () => {
    try {
      const amountAllowance = await getTokenInfo(
        addressMining,
        addressUSDT,
        address
      );
      setAllowance(amountAllowance.allowance);
    } catch (error) {
      setAllowance("0");
      console.error("error", error);
    }
  };

  useEffect(() => {
    if (address) {
      getAllowance();
    }
  }, [address]);

  const approveMining = async () => {
    const gasPrice = await Web3.eth.getGasPrice();
    try {
      setIsLoading(true);
      const contract = new Web3.eth.Contract(abiUSDT, addressUSDT);
      await contract.methods
        .approve(addressMining, ethers.MaxUint256.toString())
        .send({ from: address, gasPrice: gasPrice.toString() })
        .then(async (res: any) => {
          setIsLoading(false);
          await getAllowance();
        })
        .catch((err: any) => {
          console.error("Err approve", err);
          setIsLoading(false);
        });
    } catch (error: any) {
      console.error("Error approve", error);
      setIsLoading(false);
    }
  };

  const [transactionHash, setTransactionHash] = useState("");
  const handleMining = async () => {
    setIsLoading(true);
    if (typeof window !== "undefined" && window.ethereum) {
      const contractFarm = new Web3.eth.Contract(abiMining, addressMining);

      if (!valueInput || isNaN(valueInput)) {
        setIsLoading(false);
        toast.error("Invalid value. It must be a valid number.");
        return;
      }

      if (Number(valueInput) < 100) {
        setIsLoading(false);
        toast.error("Minimum mining is 100$");
        return;
      }

      if (Number(usdtBalance) < Number(valueInput)) {
        setIsLoading(false);
        toast.error("Insuficient balance");
        return;
      }


      const gasPrice = await Web3.eth.getGasPrice();
      const convertAmount: any = convertToWei(valueInput, 18);

      try {
        const gasEstimate = await contractFarm.methods
          .MiningUSDT(convertAmount)
          .estimateGas({
            from: address,
            gasPrice: gasPrice.toString(),
          });
        await contractFarm.methods
          .MiningUSDT(convertAmount)
          .send({
            from: address,
            gasPrice: gasPrice.toString(),
            gas: gasEstimate.toString(),
          })
          .then((res: any) => {
            if (res) {
              setIsLoading(false);
              setTransactionHash(res.transactionHash);
              dispatch(IsLoadingRedux(true));
              setValueInput(0);
              toast.success("Mining successfully");
            }
          })
          .catch((error: any) => {
            console.log("error", error);
            toast.error("Transaction has been rejected");
            setIsLoading(false);
          });
      } catch (error) {
        console.error("Error executing transaction:", error);
        toast.error("Error executing transaction");
        setIsLoading(false);
      }
    }
  };

  return (
    <TransferContainer>
      <TransferHeader>
        <h2>start your mining</h2>
        <p>Start Mining with TON Patrick minimum of 100 USDT</p>
      </TransferHeader>
      <FormBalance>
        <p>
          {!usdtBalance ? 0 : convertFixed(Number(usdtBalance))}
          <figure>
            <img
              width={21}
              height={21}
              src="/img/Common/usdt_icon.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
        </p>
        <figure>
          <img
            width={19}
            height={18}
            src="/img/Common/wallet_icon.svg"
            alt="icon"
            loading="lazy"
          />
        </figure>
      </FormBalance>
      <FormInputs>
        <p>Mining Amount</p>
        <InputCommon
          value={valueInput}
          placeHolder="Enter Amount"
          suffix={<></>}
          onKeyDown={(e: any) => {
            if (["e", "E", "-", "+"].includes(e.key)) {
              e.preventDefault();
            }
          }}
          onPaste={(e: any) => {
            const pasteData = e.clipboardData.getData("text");
            if (!/^[0-9]*\.?[0-9]*$/.test(pasteData)) {
              e.preventDefault();
              toast.error("Please enter a valid positive amount", {
                id: "invalidPaste",
              });
            }
          }}
          onChange={(e: any) => {
            const value = e.target.value;
            const sanitizedValue = value.replace(/[^0-9.]/g, "");
            setValueInput(sanitizedValue);
          }}
        />
        {transactionHash.length > 0 ? (
          <>
            <TransactionHash>
              <p>Transaction hash: </p>
              <Link to={`https://bscscan.com/tx/${transactionHash}`}>
                {shortenAddress(transactionHash, 5)}
              </Link>
            </TransactionHash>
          </>
        ) : (
          <></>
        )}
        <PerList>
          {dataPer.map((item, index) => {
            return (
              <li
                onClick={() => {
                  setPer(item.value);
                  setValueInput((Number(usdtBalance) * item.value) / 100);
                }}
                className={per === item.value ? "active" : ""}
                key={index}
              >
                {item.value}%
              </li>
            );
          })}
        </PerList>
        {address ? (
          <>
            {Number(valueInput) > Number(allowance) ? (
              <>
                <ButtonForm
                  disabled={isLoading}
                  onClick={() => {
                    approveMining();
                  }}
                >
                  {isLoading ? <Spin /> : "Approve"}
                </ButtonForm>
              </>
            ) : (
              <>
                <ButtonForm
                  disabled={isLoading}
                  onClick={() => {
                    handleMining();
                  }}
                >
                  {isLoading ? <Spin /> : "Mining"}
                </ButtonForm>
              </>
            )}
          </>
        ) : (
          <>
            <ButtonForm>Please connect wallet</ButtonForm>
          </>
        )}
      </FormInputs>
    </TransferContainer>
  );
};

const dataPer = [
  {
    id: 1,
    value: 25,
  },
  {
    id: 2,
    value: 50,
  },
  {
    id: 3,
    value: 75,
  },
  {
    id: 4,
    value: 100,
  },
];

export default Mining;
