import { Table } from "antd";
import { TableContainer } from "./styled";

const { Column } = Table;

const TableCommon = ({ data, heading, className }: any) => {
  // Function to scroll to the table when pagination changes
  const handleScrollToTable = () => {
    const tableElement = document.getElementById("table-transaction");
    if (tableElement) {
      tableElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handle pagination change and trigger the scroll function
  const handleTableChange = (pagination: any) => {
    handleScrollToTable();
  };

  return (
    <TableContainer className={className}>
      <Table
        dataSource={data}
        pagination={{ showSizeChanger: false }}
        onChange={handleTableChange}
      >
        {heading.map((item: any) => {
          return (
            <Column
              title={item.name}
              dataIndex={item.dataIndex}
              key={item.key}
              render={item.render}
              className={item.className && item.className}
            />
          );
        })}
      </Table>
    </TableContainer>
  );
};

export default TableCommon;
