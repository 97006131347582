import { useAccount } from "wagmi";
import {
  FrameWallet,
  SidebarCW,
  SidebarCWButton,
  WalletAddress,
  WalletConnectFrame,
} from "../styled";
import { shortenAddress } from "../../../utils/addressUser";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../Context";
import { useSelector } from "react-redux";
import { convertFixed } from "../../../utils/convertNumber";

const ConnectWallet = () => {
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const { isTablet } = useContext(ContextProviderWrapper)!;
  const { usdtBalance } = useSelector((state: any) => state.user);

  return (
    <SidebarCW>
      {address ? (
        <>
          <WalletConnectFrame>
            <figure
              onClick={() => {
                if (isTablet) {
                  open();
                }
              }}
            >
              <img
                width={212}
                height={156}
                src="/img/Common/wallet_connected_frame.png"
                alt="frame"
                loading="lazy"
              />
            </figure>
            <FrameWallet>
              <p>
                {convertFixed(Number(usdtBalance))}
                <img
                  width={21}
                  height={21}
                  src="/img/Common/usdt_icon.png"
                  alt="icon"
                  loading="lazy"
                />
              </p>
              <WalletAddress>
                <p>{shortenAddress(address, 6)}</p>
                <CopyToClipboard
                  text={address}
                  onCopy={() => toast.success("Coppied !")}
                >
                  <figure>
                    <img
                      width={14}
                      height={14}
                      src="/img/Common/copy_icon.svg"
                      alt="icon"
                      loading="lazy"
                    />
                  </figure>
                </CopyToClipboard>
              </WalletAddress>
            </FrameWallet>
          </WalletConnectFrame>
        </>
      ) : (
        <>
          <figure>
            <img
              className="animation-scale"
              width={99}
              height={112}
              src="/img/Sidebar/sidebar_cw_icon.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
        </>
      )}
      {isTablet ? (
        <></>
      ) : (
        <SidebarCWButton
          className={address ? "error" : ""}
          onClick={() => {
            open();
          }}
        >
          {address ? "Disconnect Wallet" : "Connect Wallet"}
        </SidebarCWButton>
      )}
    </SidebarCW>
  );
};

export default ConnectWallet;
