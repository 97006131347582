import { BlockContainer, BlockContent, BlockHeader } from "./styled";

const BlockCommon = ({ title, value, icon, subItem, subValue }: any) => {
  return (
    <BlockContainer>
      <BlockHeader>
        <figure>
          <img width={22} height={22} src={icon} alt="icon" loading="lazy" />
        </figure>
        {subItem}
      </BlockHeader>
      <BlockContent>
        <h2>{title}</h2>
        <p>{value}</p>
      </BlockContent>
      {subValue}
    </BlockContainer>
  );
};

export default BlockCommon;
