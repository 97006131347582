import BlockCommon from "../../components/Common/Block";
import HeaderCommon from "../../components/Header";
import {
  DashboardBlocks,
  DashboardInfo,
  DashboardTable,
  // TableAction,
  // TableInfo,
  // TableMobile,
} from "./styled";
import CardCommon from "../../components/Common/Card";
import TitleCommon from "../../components/Common/Title";
// import { AddressCopy, ButtonAction } from "../../Layout/styled";
import { useSelector } from "react-redux";
import { PatrickAPI } from "../../services/api";
import { useContext, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { convertFixed } from "../../utils/convertNumber";
import { ContextProviderWrapper } from "../../components/Context";
import TableMining from "../../components/MiningTable";
// import ProgressCommon from "../../components/Common/Progress";

const Dashboard = () => {
  const { userProfile } = useSelector((state: any) => state.user);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  // const tableData = [
  //   {
  //     id: 1,
  //     address: "123",
  //     sponser: "123123",
  //     totalInvest: "12312312",
  //   },
  //   {
  //     id: 2,
  //     address: "123",
  //     sponser: "123123",
  //     totalInvest: "12312312",
  //   },
  //   {
  //     id: 3,
  //     address: "123",
  //     sponser: "123123",
  //     totalInvest: "12312312",
  //   },
  //   {
  //     id: 4,
  //     address: "123",
  //     sponser: "123123",
  //     totalInvest: "12312312",
  //   },
  //   {
  //     id: 5,
  //     address: "123",
  //     sponser: "123123",
  //     totalInvest: "12312312",
  //   },
  //   {
  //     id: 6,
  //     address: "123",
  //     sponser: "123123",
  //     totalInvest: "12312312",
  //   },
  //   {
  //     id: 7,
  //     address: "123",
  //     sponser: "123123",
  //     totalInvest: "12312312",
  //   },
  //   {
  //     id: 8,
  //     address: "123",
  //     sponser: "123123",
  //     totalInvest: "12312312",
  //   },
  //   {
  //     id: 9,
  //     address: "123",
  //     sponser: "123123",
  //     totalInvest: "12312312",
  //   },
  //   {
  //     id: 10,
  //     address: "123",
  //     sponser: "123123",
  //     totalInvest: "12312312",
  //   },
  // ];

  // const headingData = isMobile
  //   ? [
  //       {
  //         name: "",
  //         dataIndex: "",
  //         key: "",
  //         render: (data: any) => {
  //           return (
  //             <TableMobile>
  //               <TableInfo>
  //                 <p>$12,000</p>
  //                 <div>
  //                   <AddressCopy>
  //                     0xd3...12f9
  //                     <figure>
  //                       <img
  //                         width={14}
  //                         height={14}
  //                         src="/img/Common/copy_2_icon.svg"
  //                         alt="icon"
  //                         loading="lazy"
  //                       />
  //                     </figure>
  //                   </AddressCopy>
  //                   <p>Date: 12/09/2024</p>
  //                 </div>
  //               </TableInfo>
  //               <TableAction>
  //                 <ButtonAction className="error">
  //                   Stop
  //                   <figure>
  //                     <img
  //                       width={16}
  //                       height={16}
  //                       src="/img/Common/unlock_icon.svg"
  //                       alt="icon"
  //                       loading="lazy"
  //                     />
  //                   </figure>
  //                 </ButtonAction>
  //               </TableAction>
  //             </TableMobile>
  //           );
  //         },
  //       },
  //     ]
  //   : [
  //       {
  //         name: "ID",
  //         dataIndex: "",
  //         key: "",
  //         render: (data: any) => {
  //           return <p>{data.id}</p>;
  //         },
  //       },
  //       {
  //         name: `Amount`,
  //         dataIndex: "",
  //         key: "",
  //         render: (data: any) => {
  //           return <p>$100</p>;
  //         },
  //       },
  //       {
  //         name: `TON Reward`,
  //         dataIndex: "",
  //         key: "",
  //         render: (data: any) => {
  //           return (
  //             <p>
  //               30{" "}
  //               <span
  //                 style={{
  //                   color: "#0073FF",
  //                 }}
  //               >
  //                 TON
  //               </span>
  //             </p>
  //           );
  //         },
  //       },
  //       {
  //         name: `Daily Mining`,
  //         dataIndex: "",
  //         key: "",
  //         render: (data: any) => {
  //           return (
  //             <ProgressCommon
  //               content={70}
  //               progress={70}
  //               backgroundColor={"red"}
  //             />
  //           );
  //         },
  //       },
  //       {
  //         name: `Start Date`,
  //         dataIndex: "",
  //         key: "",
  //         render: (data: any) => {
  //           return <p>12/09/24, 13:15</p>;
  //         },
  //       },
  //       {
  //         name: `Hash`,
  //         dataIndex: "",
  //         key: "",
  //         render: (data: any) => {
  //           return <p>0xd3...12fa9</p>;
  //         },
  //       },
  //       {
  //         name: `Action`,
  //         dataIndex: "",
  //         key: "",
  //         render: (data: any) => {
  //           return (
  //             <ButtonAction className="error">
  //               Stop Mining
  //               <figure>
  //                 <img
  //                   width={16}
  //                   height={16}
  //                   src="/img/Common/unlock_icon.svg"
  //                   alt="icon"
  //                   loading="lazy"
  //                 />
  //               </figure>
  //             </ButtonAction>
  //           );
  //         },
  //       },
  //     ];

  const { address } = useAccount();

  const [dashboard, setDashboard] = useState({
    totalInvest: 0,
    directCommission: 0,
    dailyEarning: 0,
    growthCommission: 0,
  });

  const handleTotalInvest = async () => {
    try {
      const res = await PatrickAPI.getTotalInvest(address);
      if (res.status === 200) {
        setDashboard((prevState) => ({
          ...prevState,
          totalInvest: res?.data?.data?.totalInvest,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDirectCommission = async () => {
    try {
      const res = await PatrickAPI.getDirectCommission(address);
      if (res.status === 200) {
        setDashboard((prevState) => ({
          ...prevState,
          directCommission: res?.data?.data?.totalCommission,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDailyEarning = async () => {
    try {
      const res = await PatrickAPI.getDailyEarning(address);
      if (res.status === 200) {
        setDashboard((prevState) => ({
          ...prevState,
          dailyEarning: res?.data?.data?.totalEarning,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGrowthCommission = async () => {
    try {
      const res = await PatrickAPI.getGrowthCommission(address);
      if (res.status === 200) {
        setDashboard((prevState) => ({
          ...prevState,
          growthCommission: res?.data?.data?.totalCommission,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address) {
      handleDirectCommission();
      handleTotalInvest();
      handleGrowthCommission();
      handleDailyEarning();
    }
  }, [address]);

  const handleChangeLevel = () => {
    switch (userProfile?.rank) {
      case 1:
        return (
          <CardCommon
            icon="/img/Common/level_1.png"
            level="Miner"
            className="level_1"
            backgroundColor={["#A2FF6F", "#37FF48", "#00EA13"]}
          />
        );
      case 2:
        return (
          <CardCommon
            icon="/img/Common/level_2.png"
            level="Harvester"
            className="level_2"
            backgroundColor={["#6FF8FF", "#43E9FF", "#00D0FF"]}
          />
        );
      case 3:
        return (
          <CardCommon
            icon="/img/Common/level_3.png"
            level="Guardian"
            className="level_3"
            backgroundColor={["#6FF8FF", "#43E9FF", "#00D0FF"]}
          />
        );
      case 4:
        return (
          <CardCommon
            icon="/img/Common/level_4.png"
            level="Delegate"
            className="level_4"
            backgroundColor={["#CA6FFF", "#C343FF", "#B700FF"]}
          />
        );
      case 5:
        return (
          <CardCommon
            icon="/img/Common/level_5.png"
            level="Nominator"
            className="level_5"
            backgroundColor={["#FFFA6F", "#FFEC43", "#FFD400"]}
          />
        );
      case 6:
        return (
          <CardCommon
            icon="/img/Common/level_6.png"
            level="Validator"
            className="level_6"
            backgroundColor={["#FF6FE0", "#FF43CA", "#F0A"]}
          />
        );
      case 7:
        return (
          <CardCommon
            icon="/img/Common/level_7.png"
            level="Elite Validator"
            className="level_7"
            backgroundColor={["#FF6F72", "#FF4346", "#FF0004"]}
          />
        );
      default:
        return (
          <CardCommon
            icon="/img/Common/level_0.png"
            level="Member"
            className="level_0"
            backgroundColor={["#DCDCDC", "#C1C1C1", "#949494"]}
          />
        );
    }
  };

  return (
    <div>
      {!isMobile && <HeaderCommon title="dashboard" />}
      <DashboardInfo>
        {handleChangeLevel()}
        <DashboardBlocks>
          <BlockCommon
            icon="/img/Common/totalBalance_icon.svg"
            title="Total Mining"
            value={`$${convertFixed(Number(dashboard?.totalInvest))}`}
          />
          <BlockCommon
            icon="/img/Common/totalMining_icon.svg"
            title="Growth Commission"
            value={`$${convertFixed(Number(dashboard?.growthCommission))}`}
          />
          <BlockCommon
            icon="/img/Common/totalRevenue_icon.svg"
            title="Direct Commission"
            value={`$${convertFixed(Number(dashboard?.directCommission))}`}
          />
          <BlockCommon
            icon="/img/Common/dailyProfit_icon.svg"
            title="Daily Earning"
            value={`$${convertFixed(Number(dashboard?.dailyEarning))}`}
          />
        </DashboardBlocks>
      </DashboardInfo>
      <DashboardTable>
        <TitleCommon title="YOUR MINING" />
        <TableMining />
      </DashboardTable>
    </div>
  );
};

export default Dashboard;
