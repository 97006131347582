import { useAccount } from "wagmi";
import InputCommon from "../../components/Common/Input";
import SelectCommon from "../../components/Common/Select";
import TableCommon from "../../components/Common/Table";
import HeaderCommon from "../../components/Header";
import {
  AddressCopy,
  ButtonCopy,
  TagStatus,
  TokenValue,
} from "../../Layout/styled";
import { PatrickAPI } from "../../services/api";
import { HistoryFilter, HistoryHeader, HistoryTable } from "./styled";
import { useContext, useEffect, useState } from "react";
import { shortenAddress } from "../../utils/addressUser";
import { convertFixed } from "../../utils/convertNumber";
import moment from "moment";
import { ContextProviderWrapper } from "../../components/Context";
import { TableAction, TableInfo, TableMobile } from "../Dashboard/styled";

const History = () => {
  const { address } = useAccount();
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [typeSelected, setTypeSelected] = useState("");
  const [transaction, setTransaction] = useState([]);
  const [searchText, setSearchText] = useState("");

  const headingData = isMobile
    ? [
        {
          name: "",
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TableMobile>
                <TableInfo>
                  <p>
                    {data.coin === "USDT" ? (
                      <figure>
                        <img
                          width={21}
                          height={21}
                          src="/img/Common/usdt_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      </figure>
                    ) : (
                      <figure>
                        <img
                          src="/img/Common/ton_patrick_token.svg"
                          alt="icon"
                          loading="lazy"
                        />
                      </figure>
                    )}
                    {data.amount}
                  </p>
                  <div>
                    {data.transactionHash ? (
                      <AddressCopy>
                        {shortenAddress(data.transactionHash, 4)}
                        <figure>
                          <img
                            width={14}
                            height={14}
                            src="/img/Common/copy_2_icon.svg"
                            alt="icon"
                            loading="lazy"
                          />
                        </figure>
                      </AddressCopy>
                    ) : (
                      "--"
                    )}
                    <p>
                      Date: {moment(data.createdAt).format("D/M/YYYY HH:mm:ss")}
                    </p>
                  </div>
                </TableInfo>
                <TableAction>
                  <div>{handleSwitchTypeReverse(data.type)}</div>
                  <TagStatus
                    className="success"
                    style={{
                      margin: isMobile ? "0" : "0 auto",
                    }}
                  >
                    <figure>
                      <img
                        width={15}
                        height={16}
                        src="/img/Common/success_icon.svg"
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                    {/* <p>{data.status}</p> */}
                    <p>Success</p>
                  </TagStatus>
                </TableAction>
              </TableMobile>
            );
          },
        },
      ]
    : [
        {
          name: "ID Transaction",
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{shortenAddress(data._id, 4)}</p>;
          },
        },
        {
          name: `Type`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <ButtonCopy
                style={{
                  margin: "0 auto",
                }}
              >
                {handleSwitchTypeReverse(data.type)}
              </ButtonCopy>
            );
          },
        },
        {
          name: `Amount`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TokenValue>
                {data.coin === "USDT" ? (
                  <figure>
                    <img
                      width={21}
                      height={21}
                      src="/img/Common/usdt_icon.png"
                      alt="icon"
                      loading="lazy"
                    />
                  </figure>
                ) : (
                  <figure>
                    <img
                      src="/img/Common/ton_patrick_token.svg"
                      alt="icon"
                      loading="lazy"
                    />
                  </figure>
                )}
                {convertFixed(Number(data.amount))}
              </TokenValue>
            );
          },
        },
        // {
        //   name: `Wallet`,
        //   dataIndex: "",
        //   key: "",
        //   render: (data: any) => {
        //     return (
        //       <AddressCopy>
        //         0xd3...12f9
        //         <figure>
        //           <img
        //             width={14}
        //             height={14}
        //             src="/img/Common/copy_2_icon.svg"
        //             alt="icon"
        //             loading="lazy"
        //           />
        //         </figure>
        //       </AddressCopy>
        //     );
        //   },
        // },
        {
          name: `Txn Hash`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <AddressCopy>
                {data.transactionHash ? (
                  <>
                    {shortenAddress(data.transactionHash, 5)}
                    <figure>
                      <img
                        width={14}
                        height={14}
                        src="/img/Common/copy_2_icon.svg"
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                  </>
                ) : (
                  "--"
                )}
              </AddressCopy>
            );
          },
        },
        {
          name: `Date`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{moment(data.createdAt).format("D/M/YYYY HH:mm:ss")}</p>;
          },
        },
        {
          name: `Status`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TagStatus
                className="success"
                style={{
                  margin: "0 auto",
                }}
              >
                <figure>
                  <img
                    width={15}
                    height={16}
                    src="/img/Common/success_icon.svg"
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
                {/* <p>{data.status}</p> */}
                <p>Success</p>
              </TagStatus>
            );
          },
        },
      ];

  const dataSelect = [
    {
      title: "Cancel Invest",
      img: "",
    },
    {
      title: "Withdraw",
      img: "",
    },
    {
      title: "Transfer",
      img: "",
    },
    // {
    //   title: "Swap",
    //   img: "",
    // },
    {
      title: "Reward Commission",
      img: "",
    },
    {
      title: "Direct Commission",
      img: "",
    },
    {
      title: "Indirect Commission",
      img: "",
    },
    {
      title: "Invest",
      img: "",
    },
  ];

  const handleSwitchType = () => {
    switch (typeSelected) {
      case "Cancel Invest":
        return "ci";
      case "Withdraw":
        return "wd";
      case "Transfer":
        return "tf";
      // case "Swap":
      //   return "sw";
      case "Reward Commission":
        return "rc";
      case "Direct Commission":
        return "dc";
      case "Indirect Commission":
        return "ic";
      case "Invest":
        return "iv";
      default:
        return "";
    }
  };

  const handleSwitchTypeReverse = (type: any) => {
    switch (type) {
      case "ci":
        return "Cancel Invest";
      case "wd":
        return "Withdraw";
      case "tf":
        return "Transfer";
      // case "sw":
      //   return "Swap";
      case "rc":
        return "Reward Commission";
      case "dc":
        return "Direct Commission";
      case "ic":
        return "Indirect Commission";
      case "iv":
        return "Invest";
      default:
        return "";
    }
  };

  const getTableHistory = async () => {
    const param = {
      address: address,
      page: 1,
      limit: 1000,
      type: handleSwitchType(),
    };
    try {
      const res = await PatrickAPI.getHistory(param);
      if (res.status === 200) {
        const sortedData = res.data.data.data.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setTransaction(sortedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address || typeSelected) {
      getTableHistory();
    }
  }, [typeSelected, address]);

  const filteredTransactions = transaction.filter((txn: any) => {
    const status = txn.status ? txn.status.toLowerCase().trim() : "";
    const searchValue = searchText.toLowerCase().trim();
    const matchesSearch = status.includes(searchValue);
    return matchesSearch;
  });

  return (
    <div>
      <HeaderCommon title="history" />
      <HistoryHeader>
        <HistoryFilter>
          <InputCommon
            placeHolder="Search"
            onChange={(e: any) => setSearchText(e.target.value)}
          />
          <SelectCommon
            defaultValue="Transfer"
            data={dataSelect}
            onChange={(e: any) => {
              setTypeSelected(e);
            }}
          />
        </HistoryFilter>
      </HistoryHeader>
      <HistoryTable>
        <TableCommon data={filteredTransactions} heading={headingData} />
      </HistoryTable>
    </div>
  );
};

export default History;
