import styled from "styled-components";

export const KeyvisualContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    inset: 0;
    background: url("/gif/banner_bg.gif") no-repeat center / cover;
    opacity: 0.02;
    pointer-events: none;
  }
`;
export const KeyvisualWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  h2 {
    position: relative;
    width: max-content;
    color: #0085ff;
    text-align: center;
    font-size: 96px;
    font-weight: 700;
    margin-bottom: 20px;
    /* &::before {
      content: "";
      position: absolute;
      left: -15px;
      top: 5px;
      width: 6px;
      height: 66px;
      background-color: #fff;
    } */
  }
  .type-animation {
    &:after {
      color: #fff;
    }
  }
  & > p {
    display: flex;
    align-items: center;
    font-size: 63px;
    color: #fff;
    font-weight: bold;
    gap: 15px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  @media (max-width: 1023px) {
    h2 {
      font-size: 35px;
      &::before {
        width: 4px;
        height: 40px;
      }
    }
    & > p {
      font-size: 36px;
    }
  }
`;
export const TextBrand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10.45px;
  background: rgba(62, 162, 255, 0.29);
  backdrop-filter: blur(3px);
  img {
    position: relative;
    top: 5px;
  }
  @media (max-width: 1023px) {
    padding: 3px 2px;
    img {
      width: 155px;
      height: 52px;
      object-fit: cover;
    }
  }
`;
export const KeyvisualDescription = styled.div`
  margin-top: 25px;
  p {
    position: relative;
    color: rgba(255, 255, 255, 0.38);
    text-align: center;
    font-size: 21px;
    font-weight: 500;
    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 8px;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background-color: #06f;
    }
  }
  @media (max-width: 1023px) {
    p {
      font-size: 14px;
    }
  }
`;
