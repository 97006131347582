import { Link } from "react-router-dom";
import {
  ButtonMenu,
  HeaderLandingContainer,
  HeaderLandingWrapper,
  LandingNavigation,
  LandingNavigationContent,
} from "./styled";
import { ButtonCommon } from "../../../Layout/styled";
import { ContextProviderWrapper } from "../../Context";
import { useContext, useState } from "react";
import {
  FooterDescription,
  FooterInfo,
  FooterLogo,
  FooterSocials,
} from "../Footer/styled";

const HeaderLanding = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const activeMenu = localStorage.getItem("isMenu");

  const handleOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const handleActiveMenu = (name: string) => {
    localStorage.setItem("isMenu", name);
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <HeaderLandingContainer>
      <HeaderLandingWrapper>
        <Link to="/">
          <img
            width={112}
            height={36}
            src="/img/Landing/logo_landing.svg"
            alt="icon"
            loading="lazy"
          />
        </Link>
        {!isDesktop && (
          <ButtonMenu
            onClick={handleOpenMenu}
            className={`${isOpenMenu ? "active" : ""}`}
          >
            <img
              className="icon-menu-normal"
              src="/img/Common/sp/icn_header_08.png"
              alt="icon"
              width={41}
              height={41}
              loading="lazy"
            />
            <img
              className="icon-menu-active"
              src="/img/Common/sp/icn_header_09.png"
              alt="icon"
              width={41}
              height={41}
              loading="lazy"
            />
          </ButtonMenu>
        )}
        <LandingNavigationContent className={`${isOpenMenu ? "active" : ""}`}>
          <LandingNavigation>
            {LnData.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    href={item.link}
                    className={`${activeMenu === item.name ? "active" : ""}`}
                    onClick={() => handleActiveMenu(item.name)}
                  >
                    <div className="content-left">
                      {!isDesktop && (
                        <div className="content-image">
                          <img
                            src={item.icon}
                            alt="icon"
                            width={20}
                            height={20}
                            loading="lazy"
                            className="icon-menu-normal"
                          />
                          <img
                            src={item.iconActive}
                            alt="icon"
                            width={20}
                            height={20}
                            loading="lazy"
                            className="icon-menu-active"
                          />
                        </div>
                      )}
                      {item.name}
                    </div>
                    {!isDesktop && (
                      <span className="check-active">
                        <img
                          src="/img/Common/sp/icn_header_07.png"
                          width={16}
                          height={16}
                          alt="icon"
                          loading="lazy"
                        />
                        You’re here!
                      </span>
                    )}
                  </a>
                </li>
              );
            })}
          </LandingNavigation>
          {!isDesktop && (
            <>
              <FooterInfo>
                <FooterLogo to="/">
                  <img
                    width={112}
                    height={36}
                    src="/img/Landing/logo_landing.svg"
                    alt="icon"
                    loading="lazy"
                  />
                </FooterLogo>
                <FooterDescription>
                  TON Patrick is a mining solution developed on the TON
                  <br className="md" /> Blockchain platform to provide an
                  efficient, fast and secure
                  <br className="md" /> mining system.
                </FooterDescription>
                <FooterSocials>
                  {socialData.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={item.href} target="_blank" rel="noreferrer">
                          <figure>
                            <img src={item.logo} alt="icon" loading="lazy" />
                          </figure>
                          {isDesktop && <p>{item.text}</p>}
                        </Link>
                      </li>
                    );
                  })}
                </FooterSocials>
              </FooterInfo>
            </>
          )}
        </LandingNavigationContent>
        <ButtonCommon>
          <Link to="/dashboard">
            Launch App
            <img src="/img/Common/button_arrow.svg" alt="icon" loading="lazy" />
          </Link>
        </ButtonCommon>
      </HeaderLandingWrapper>
    </HeaderLandingContainer>
  );
};

const LnData = [
  {
    id: 1,
    name: "Home",
    link: "/",
    icon: "/img/Common/sp/icn_header_01.png",
    iconActive: "/img/Common/sp/icn_header_01_active.png",
  },
  {
    id: 2,
    name: "Benifits",
    link: "#benefits",
    icon: "/img/Common/sp/icn_header_02.png",
    iconActive: "/img/Common/sp/icn_header_02_active.png",
  },
  {
    id: 3,
    name: "How we works",
    link: "#features",
    icon: "/img/Common/sp/icn_header_03.png",
    iconActive: "/img/Common/sp/icn_header_03_active.png",
  },
  {
    id: 4,
    name: "Audit",
    link: "#started",
    icon: "/img/Common/sp/icn_header_04.png",
    iconActive: "/img/Common/sp/icn_header_04_active.png",
  },
  {
    id: 5,
    name: "Roadmap",
    link: "#roadmap",
    icon: "/img/Common/sp/icn_header_05.png",
    iconActive: "/img/Common/sp/icn_header_05_active.png",
  },
  {
    id: 6,
    name: "FAQs",
    link: "#faqs",
    icon: "/img/Common/sp/icn_header_06.png",
    iconActive: "/img/Common/sp/icn_header_06_active.png",
  },
];

const socialData = [
  {
    id: 1,
    logo: "/img/Landing/telegram_icon.svg",
    text: "Telegram",
    href: "",
  },
  {
    id: 2,
    logo: "/img/Landing/youtube_icon.svg",
    text: "Youtube",
    href: "",
  },
  {
    id: 3,
    logo: "/img/Landing/x_icon.svg",
    text: "X/Twitter",
    href: "",
  },
  {
    id: 4,
    logo: "/img/Landing/discord_icon.svg",
    text: "Discord",
    href: "",
  },
];

export default HeaderLanding;
