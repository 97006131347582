import styled from "styled-components";

export const BgMenu = styled.div`
  position: fixed;
  background: rgba(16, 16, 16, 0.56);
  backdrop-filter: blur(38.5px);
  z-index: 100;
  transition: all 0.15s linear;
  opacity: 0;
  inset: 0;
  pointer-events: none;
  &.open {
    opacity: 1;
  }
`;

export const MenuMobileContainer = styled.div`
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 12px;
  background: #2d2d2d;
  padding: 10px 14px;
  width: calc(100% - 66px);
  z-index: 100;
`;
export const MenuSideBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
export const MenuSelect = styled.div`
  width: 41px;
  height: 41px;
  margin-left: auto;
  & > p {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.28px;
  }
`;
export const MenuContent = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  transform: translateY(150%);
  transition: all 0.3s linear;
  bottom: 0;
  right: 0;
  z-index: 101;
  overflow-y: auto;
  &.open {
    transform: translate(0);
  }
`;
export const MenuAddress = styled.div`
  padding: 10px 12px;
  border-radius: 12px;
  background: rgba(147, 147, 147, 0.50);
`
