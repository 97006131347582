import styled from "styled-components";
import { TitlePage } from "../../../components/Landing/Title/styled";
import { BenefitsHeader } from "../Benefits/styled";

export const SecurityContainer = styled.div`
  padding-top: 100px;
  ${TitlePage} {
    justify-content: center;
  }
  ${BenefitsHeader} {
    text-align: center;
    p {
      &:before {
        position: static;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
`;
export const BlockSlider = styled.div`
  position: absolute;
  top: 50.7%;
  left: 50.2%;
  transform: translate(-50%, -50%);
  width: 50%;
  text-align: center;
  &::before,
  &::after {
    border-radius: 50%;
    position: absolute;
    content: "";
    width: 14px;
    height: 14px;
    left: 50%;
    transform: translateX(-50%);
    background: url("/img/Landing/mark_features.svg") no-repeat center center;
    z-index: 10;
    pointer-events: none;
  }
  &::before {
    top: 109px;
  }
  &:after {
    bottom: 109px;
  }
  .content-image {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80.01px;
    border: 2px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
    background: linear-gradient(
        0deg,
        var(--neutral-neutral-430, rgba(18, 18, 18, 0.3)) 0%,
        var(--neutral-neutral-430, rgba(18, 18, 18, 0.3)) 100%
      ),
      var(--neutral-neutral-21, rgba(248, 248, 248, 0.01));
    box-shadow: 0px 0px 10.668px 0px rgba(248, 248, 248, 0.25) inset,
      0px 42.672px 32.004px -21.336px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(8.001030921936035px);
  }
  .content-logo {
    position: absolute;
    bottom: -114px;
    left: 50%;
    margin-left: -55px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .swiper {
    padding-bottom: 230px;
  }
  .swiper-wrapper {
    top: 115px;
  }
  .swiper-slide {
    opacity: 0.5;
    transition: opacity 0.3s ease;
    &.swiper-slide-active {
      opacity: 1;
      .content-logo {
        opacity: 1;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: unset;
    transform: translateX(-50%);
    bottom: 0;
    width: 41px;
    height: 41px;
    background: url("/img/Landing/Roadmap/roadmap_icn_01.png") center / cover
      no-repeat;
    border-radius: 50%;
    &:after {
      content: none;
    }
  }
  .swiper-button-prev {
    left: 50px;
  }
  .swiper-button-next {
    transform: scaleX(-1) translateX(-50%);
    right: 50px;
  }
`;
export const SecurityContent = styled.div`
  display: flex;
  align-items: center;
  .content-left {
    position: relative;
  }
  .content-right {
    max-width: 461px;
    h3 {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 10px;
    }
    p {
      color: rgba(255, 255, 255, 0.43);
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.18px;
      margin-bottom: 14px;
    }
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    .content-left {
      margin-bottom: 20px;
    }
    .content-right {
      text-align: center;
    }
  }
`;
