import { Outlet, useParams } from "react-router-dom";
import { DashboardBody, DashboardBodyWrapper } from "./styled";
import Sidebar from "../../components/Sidebar";
import { useContext, useEffect } from "react";
import { ContextProviderWrapper } from "../../components/Context";
import MenuMobile from "../../components/MenuMobile";
import { PatrickAPI } from "../../services/api";
import { useAccount, useSignMessage } from "wagmi";
import web3 from "web3";
import { convertFromWei } from "../../utils/convertNumber";
import {
  GetBalancesDB,
  getTokenInfo,
  GetUSDTBalanceWallet,
  IsLoadingRedux,
  UserOverview,
} from "../../redux/slices/user";
import { useDispatch } from "../../redux/store";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { abiUSDT } from "../../contract/Pair";
import { addressUSDT } from "../../contract/AddressContract";

const DashboardWrapper = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const { address } = useAccount();
  const verifyMessage = "Verify";
  const { isLoading } = useSelector((state: any) => state.user);
  const { data: signature, signMessage } = useSignMessage();
  // Catch ref
  const { ref } = useParams();
  const dispatch = useDispatch();

  const handleVerifyWallet = async () => {
    signMessage({ message: verifyMessage });
  };

  useEffect(() => {
    if (address) {
      handleVerifyWallet();
    }
  }, [address]);

  const handleVerify = async () => {
    const params = {
      address: address,
      signature: signature,
      message: "Verify",
      referralId: !ref ? "" : ref,
    };
    try {
      const res = await PatrickAPI.verify(params);
      if (res.status === 200 && res.data.data.verified) {
        await handleGetInfo();
        await handleGetBalanceDB();
      }
    } catch (error) {
      console.log(error);
      toast.error("Login error");
    }
  };

  useEffect(() => {
    if (signature) {
      handleVerify();
    }
  }, [signature]);

  const handleGetInfo = async () => {
    try {
      const res = await PatrickAPI.getInfo(address);
      if (res.status === 200) {
        dispatch(UserOverview(res.data.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUSDTBalance = async () => {
    try {
      const Web3Balance = new web3("https://bsc-dataseed.binance.org/");
      const contract = new Web3Balance.eth.Contract(abiUSDT, addressUSDT);

      const balance = await contract.methods.balanceOf(address).call();
      const formattedBalance: any = convertFromWei(balance, 18);
      dispatch(GetUSDTBalanceWallet(Number(formattedBalance)));
    } catch (error) {
      console.error("Error fetching BNB balance:", error);
    }
  };

  const handleGetTokenInfo = async () => {
    try {
      const res = await PatrickAPI.getTokenInfo();
      if (res.status === 200) {
        dispatch(getTokenInfo(res.data.data.tokenInfo));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address && !signature) {
      getUSDTBalance();
      handleGetInfo();
      handleGetBalanceDB();
      handleGetTokenInfo();
    }
  }, [address]);

  const handleGetBalanceDB = async () => {
    try {
      const res = await PatrickAPI.getBalances(address);
      if (res.status === 200) {
        dispatch(GetBalancesDB(res.data.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleRefetchData = async () => {
      await getUSDTBalance();
      await handleGetBalanceDB();
      await handleGetInfo();
      await dispatch(IsLoadingRedux(false));
    };
    if (isLoading) {
      handleRefetchData();
    }
  }, [isLoading]);

  return (
    <DashboardBody>
      {!isMobile ? <Sidebar /> : <MenuMobile />}
      <DashboardBodyWrapper>
        <Outlet />
      </DashboardBodyWrapper>
    </DashboardBody>
  );
};

export default DashboardWrapper;
