import styled from "styled-components";
import { BlockContainer } from "../../components/Common/Block/styled";
import { FormBalance } from "../Wallet/Transfer/styled";

export const NetworkBody = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  ${BlockContainer} {
    width: 33.33%;
  }
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    ${BlockContainer} {
      width: calc(50% - 28px);
      &:nth-child(3) {
        width: 100%;
      }
    }
  }
`;
export const NetworkLevel = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 12px;
  background: #3f3f3f;
  @media screen and (max-width: 1023px) {
    padding: 0;
    background-color: transparent;
    display: block;
  }
`;
export const NetworkLevelList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  & > li {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    width: calc(33.33% - 32px);
    min-height: 236px;
    padding: 16px 12px;
    border-radius: 8px;
    background: #333;
    border: 1px solid transparent;
    transition: all 0.15s linear;
    cursor: pointer;
    &:hover,
    &.active {
      border-color: #6a6a6a;
      background: #252525;
    }
    & > figure {
      position: absolute;
      right: 12px;
      opacity: 0.8;
      mix-blend-mode: luminosity;
    }
  }
  @media screen and (max-width: 1023px) {
    flex-direction: row;
    flex-wrap: unset;
    overflow-x: auto;
    margin-bottom: 12px;
    & > li {
      min-width: 234px;
      width: auto;
    }
  }
`;
export const NetworkLevelDetail = styled.div`
  position: relative;
  max-width: 370px;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background: linear-gradient(180deg, #83b5ff 18.88%, #e2f3ff 100%);
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    background: url("/img/Network/level_ball_decor.png") no-repeat center / 100%
      auto;
    right: 0;
    top: 0;
    width: 85px;
    height: 93px;
  }
  @media screen and (max-width: 1023px) {
    max-width: unset;
    width: auto;
  }
`;
export const NetworkInfo = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    p {
      color: rgba(226, 232, 255, 0.7);
      font-size: 13px;
    }
    span {
      text-align: right;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;
export const NetworkIcon = styled.div`
  background-color: rgba(248, 248, 248, 0.16);
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-blend-mode: luminosity;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.3));
  backdrop-filter: blur(13px);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LevelDetailTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
  h2 {
    color: #282828;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
  p {
    padding: 4px 8px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    border-radius: 77px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(248, 248, 248, 0.19);
    background-blend-mode: luminosity;
  }
`;
export const LevelDetailList = styled.ul`
  position: relative;
  height: 490px;
  overflow-y: auto;
  &.no-data {
    & > li {
      height: 96%;
    }
  }
  & > li {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    padding: 8px;
    border-radius: 6px;
    background: rgba(85, 144, 255, 0.22);
    backdrop-filter: blur(10px);
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    & > p {
      color: #727272;
      font-size: 16px;
    }
    & > div {
      text-align: center;
      p {
        &:nth-child(1) {
          color: #616161;
          font-size: 12px;
        }
        &:nth-child(2) {
          color: #464646;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
`;
export const NetworkQR = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  padding: 16px;
  flex-direction: column;
  border-radius: 8px;
  background: url("/img/Common/refCode_bg.png") no-repeat center / cover;
  ${FormBalance} {
    margin-bottom: 25px;
    & > figure {
      width: 26px;
      height: 26px;
    }
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;
export const QRWrapper = styled.div`
  width: 102px;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 2px solid rgba(248, 248, 248, 0.1);
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(106, 168, 255, 0.4) 100%
    ),
    #fff;
  padding: 7px 5px;
`;
export const QRQuote = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 12px;
  background: rgba(71, 142, 255, 0.15);
  p {
    color: #277eff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.12px;
  }
`;
export const NetworkLevelData = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  figure {
    position: absolute;
    left: -20px;
    top: 50%;
    margin-top: -15px;
    width: 45px;
    height: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    padding-left: 25px;
  }
  @media screen and (max-width: 767px) {
    figure {
      left: -15px;
    }
  }
`