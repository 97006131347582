import { HeaderNotificationIcon } from "../styled";

const Notification = () => {
  return (
    <HeaderNotificationIcon>
      <img
        width={21}
        height={21}
        src="/img/Common/notification_icon.svg"
        alt="icon"
        loading="lazy"
      />
    </HeaderNotificationIcon>
  );
};

export default Notification;
