import styled from "styled-components";
import { FormVisualContainer } from "../../components/Common/Form/Visual/styled";
import { BlockContainer } from "../../components/Common/Block/styled";
import { TableContainer } from "../../components/Common/Table/styled";

export const StakingBody = styled.div`
  display: flex;
  gap: 10px;
  ${FormVisualContainer} {
    max-width: 352px;
    width: 100%;
    min-height: 850px;
  }
  @media screen and (max-width: 1023px) {
    display: block;
    ${FormVisualContainer} {
      max-width: unset;
      width: auto;
      min-height: 500px;
      margin-bottom: 15px;
    }
  }
`;
export const StakingInfo = styled.div`
  width: 100%;
  & > div {
    &:nth-child(1) {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      padding: 12px;
      margin-bottom: 10px;
      border-radius: 8px;
      background: #585858;
      ${BlockContainer} {
        width: calc(33.33% - 30px);
      }
      & > h2 {
        display: block;
        width: 100%;
        text-align: center;
        font-family: Bahnschrift;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 3px;
        img {
          margin-right: 5px;
        }
      }
    }
  }
  ${TableContainer} {
    .ant-table-wrapper {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    & > div {
      &:nth-child(1) {
        flex-wrap: wrap;
        gap: 4px;
        ${BlockContainer} {
          width: calc(50% - 26px);
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }
`;
export const TransactionHash = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  a {
    display: block;
    color: #000;
  }
`