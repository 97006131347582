import TitleLanding from "../../../components/Landing/Title";
import { BenefitsHeader } from "../Benefits/styled";
import { BlockSlider, SecurityContainer, SecurityContent } from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { ButtonCommon } from "../../../Layout/styled";
import { Link } from "react-router-dom";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useState } from "react";

const Security = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onChange = (e: any) => {
    setActiveIndex(e.realIndex + 1);
  };

  return (
    <SecurityContainer id="security">
      <BenefitsHeader>
        <TitleLanding title="TON Patrick Security" />
        <p>Ton Patrick is secure to the core</p>
      </BenefitsHeader>
      <SecurityContent>
        <div className="content-left">
          <img
            src="/img/Landing/Security/security_img_01.png"
            width={619}
            alt=""
          />
          <BlockSlider>
            <Swiper
              spaceBetween={25}
              slidesPerView={3}
              loop={true}
              className="swiper-security"
              initialSlide={1}
              centeredSlides={true}
              navigation={true}
              modules={[Navigation]}
              onSlideChange={onChange}
            >
              {listImage.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="content-image">
                    <img src={item.img} alt="" width={44} height={44} />
                  </div>
                  <div className="content-logo">
                    <img src={item.logo} width={111} alt="" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </BlockSlider>
        </div>
        <div className="content-right">
          {listContent.map((item: any, index: number) => (
            <>
              {activeIndex === item.id ? (
                <>
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                  <ButtonCommon className="blue">
                    <Link to={item.href} target={item.href !== '#' ? '_blank' : ''} rel="noreferrer">
                      Launch App
                      <img
                        src="/img/Common/arrow_white.svg"
                        alt="icon"
                        loading="lazy"
                      />
                    </Link>
                  </ButtonCommon>
                </>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
      </SecurityContent>
    </SecurityContainer>
  );
};

const listImage = [
  {
    img: "/img/Landing/Security/security_img_02.png",
    logo: "/img/Landing/Security/security_img_06.png",
  },
  {
    img: "/img/Landing/Security/security_img_03.png",
    logo: "/img/Landing/Security/security_img_07.png",
  },
  {
    img: "/img/Landing/Security/security_img_04.png",
    logo: "/img/Landing/Security/security_img_06.png",
  },
  {
    img: "/img/Landing/Security/security_img_05.png",
    logo: "/img/Landing/Security/security_img_07.png",
  },
];
const listContent = [
  {
    id: 1,
    title: "Security",
    href: '#',
    content:
      "We have been audited by Certik, a top-ranked blockchain security company.",
  },
  {
    id: 2,
    title: "Open Source",
    href: 'https://github.com/ton-blockchain/liquid-staking-contract/tree/35d676f6ac6e35e755ea3c4d7d7cf577627b1cf0',
    content:
      "The future must be accessible to all, so our protocol is open source.",
  },
  {
    id: 3,
    title: "Non-Custodial",
    href: '#',
    content:
      "The stakes remain completely under your control, without intermediaries.",
  },
  {
    id: 4,
    title: "Built to Last",
    href: 'https://teststaking.xyz/about',
    content: "Built in alliance with TON Core Developer",
  },
];
export default Security;
