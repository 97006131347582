import styled from "styled-components";

export const InfoContainer = styled.div`
  padding: 80px 0;
`;
export const InfoContainerWrapper = styled.div`
  border-radius: 12px;
  background: rgba(177, 177, 177, 0.05);
  backdrop-filter: blur(5px);
  padding: 20px 40px;
  @media (max-width: 1023px) {
    padding: 8px;
  }
`;
export const InfoList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > li {
    position: relative;
    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        right: -85px;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 52px;
        background: rgba(255, 255, 255, 0.15);
      }
    }
    p {
      &:nth-child(1) {
        color: #fff;
        font-family: Bahnschrift;
        font-size: 44px;
        font-weight: 700;
        text-transform: uppercase;
      }
      &:nth-child(2) {
        text-align: center;
        color: #828282;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  @media (max-width: 1023px) {
    flex-wrap: wrap;
    gap: 5px;
    & > li {
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.09);
      width: calc(100% / 2 - 17px);
      text-align: center;
      padding: 7px;
      &:not(:last-child) {
        &::before {
          display: none;
        }
      }
      p {
        &:nth-child(1) {
          font-size: 28px;
        }
        &:nth-child(2) {
          font-size: 14px;
        }
      }
    }
  }
`;
