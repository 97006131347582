import styled from "styled-components";

export const FormVisualContainer = styled.div`
  position: relative;
  border-radius: 8px;
  background: linear-gradient(180deg, #83b5ff 18.88%, #e2f3ff 100%);
  padding: 12px 24px;
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    pointer-events: none;
    left: 0;
    top: 0;
  }
  &::before {
    background-image: url("/img/Common/formVisual_coin.png");
    width: 140px;
    height: 136px;
  }
  &::after {
    left: 0;
    width: 100%;
    height: 179px;
    background-image: url("/img/Common/formVisual_dots.png");
  }
  @media screen and (max-width: 767px) {
    padding: 12px;
  }
`;
export const FormVisualWarning = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: rgba(71, 142, 255, 0.15);
  padding: 16px;
  color: #277eff;
  font-size: 11px;
  font-weight: 500;
  figure {
    width: 14px;
    height: 14px;
  }
`;
