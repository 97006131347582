import { Link } from "react-router-dom";
import {
  FooterContainer,
  FooterCopyright,
  FooterDescription,
  FooterInfo,
  FooterInner,
  FooterLogo,
  FooterNavigation,
  FooterSocials,
  FooterWrapper,
} from "./styled";
import { ContextProviderWrapper } from "../../Context";
import { useContext } from "react";

const FooterLanding = () => {
  const {isDesktop} = useContext(ContextProviderWrapper)!
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterInner>
          <FooterInfo>
            <FooterLogo to="/">
              <img
                width={112}
                height={36}
                src="/img/Landing/logo_landing.svg"
                alt="icon"
                loading="lazy"
              />
            </FooterLogo>
            <FooterDescription>
              TON Patrick is a mining solution developed on the TON
              <br className="md" /> Blockchain platform to provide an efficient, fast and
              secure
              <br className="md" /> mining system.
            </FooterDescription>
            <FooterSocials>
              {socialData.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.href} target="_blank" rel="noreferrer">
                      <figure>
                        <img src={item.logo} alt="icon" loading="lazy" />
                      </figure>
                      {isDesktop && <p>{item.text}</p>}
                    </Link>
                  </li>
                );
              })}
            </FooterSocials>
          </FooterInfo>
          <FooterNavigation>
            {footerNavigationData.map((item, index) => {
              return (
                <li key={index}>
                  <h2>{item.title}</h2>
                  <ul>
                    {item.links.map((link, i) => {
                      return (
                        <li key={i}>
                          <Link to={link.href}>{link.text}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </FooterNavigation>
        </FooterInner>
        <FooterCopyright>
          <small>TON Patrick All right reserved</small>
        </FooterCopyright>
      </FooterWrapper>
    </FooterContainer>
  );
};

const footerNavigationData = [
  {
    id: 1,
    title: "Quick Link",
    links: [
      {
        id: 1,
        text: "Home",
        href: "/",
      },
      {
        id: 2,
        text: "About Us",
        href: "/",
      },
      {
        id: 3,
        text: "Blog/News",
        href: "/",
      },
      {
        id: 4,
        text: "Roadmap",
        href: "/",
      },
      {
        id: 5,
        text: "Whitepaper",
        href: "/",
      },
    ],
  },
  {
    id: 2,
    title: "Resource",
    links: [
      {
        id: 1,
        text: "Documentation",
        href: "/",
      },
      {
        id: 2,
        text: "API Access",
        href: "/",
      },
      {
        id: 3,
        text: "Github Repository",
        href: "/",
      },
      {
        id: 4,
        text: "Developer Group",
        href: "/",
      },
    ],
  },
  {
    id: 3,
    title: "Terms & Legal",
    links: [
      {
        id: 1,
        text: "Privacy Policy",
        href: "/",
      },
      {
        id: 2,
        text: "Terms of Service",
        href: "/",
      },
      {
        id: 3,
        text: "Disclaimer",
        href: "/",
      },
    ],
  },
];

const socialData = [
  {
    id: 1,
    logo: "/img/Landing/telegram_icon.svg",
    text: "Telegram",
    href: "",
  },
  {
    id: 2,
    logo: "/img/Landing/youtube_icon.svg",
    text: "Youtube",
    href: "",
  },
  {
    id: 3,
    logo: "/img/Landing/x_icon.svg",
    text: "X/Twitter",
    href: "",
  },
  {
    id: 4,
    logo: "/img/Landing/discord_icon.svg",
    text: "Discord",
    href: "",
  },
];

export default FooterLanding;
