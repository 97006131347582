import { useEffect, useState } from "react";
import {
  ButtonForm,
  FormBalance,
  FormInputs,
  PerList,
  TransferContainer,
  TransferHeader,
} from "../Transfer/styled";
import InputCommon from "../../../components/Common/Input";
import { useAccount, useSignMessage } from "wagmi";
import { useSelector } from "react-redux";
import { useDispatch } from "../../../redux/store";
import toast from "react-hot-toast";
import { Spin } from "antd";
import { convertFixed } from "../../../utils/convertNumber";
import SelectCommon from "../../../components/Common/Select";
import { PatrickAPI } from "../../../services/api";
import { IsLoadingRedux } from "../../../redux/slices/user";
import { FormInfo } from "../styled";

const Withdraw = ({ handleGetWithdrawHistory }: any) => {
  const [per, setPer] = useState(0);
  const { address } = useAccount();
  const { balancesDB } = useSelector((state: any) => state.user);
  const [valueInput, setValueInput] = useState<any>(0);
  const [selectData, setSelectData] = useState("TPT");
  const { data: signature, signMessage, isError } = useSignMessage();
  const [isLoading, setIsLoading] = useState(false);
  const verifyMessage = "Transfer";
  const dispatch = useDispatch();
  const dataSelect = [
    {
      title: "TPT",
      img: "/img/Common/ton_patrick_token.svg",
    },
    {
      title: "USDT",
      img: "/img/Common/usdt_icon.png",
    },
  ];

  const [checkSign, setCheckSign] = useState(false);
  const handleApproveTransfer = async () => {
    setIsLoading(true);
    if (!valueInput) {
      setIsLoading(false);
      toast.error("Please fill value");
      return;
    }
    if (
      Number(balancesDB?.balances[selectData === "TPT" ? 1 : 0]?.amount) <
      Number(valueInput)
    ) {
      setIsLoading(false);
      toast.error("Insufficient balance");
      return;
    }
    try {
      signMessage({ message: verifyMessage });
      setCheckSign(true);
    } catch (error) {
      console.log(error);
      setCheckSign(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError) {
      setIsLoading(false);
      setCheckSign(false);
      toast.error("User rejected", { id: "isError" });
    }
  }, [isError]);

  const handleWithdraw = async () => {
    const param = {
      network: "BEP20",
      coin: selectData,
      address: address,
      signature: signature,
      message: "Withdraw",
      toAddress: address,
      amount: Number(valueInput),
    };
    try {
      const res = await PatrickAPI.withdraw(param);
      if (res.status === 200 && res.data.success) {
        setIsLoading(false);
        toast.success("Withdraw successfully");
        setCheckSign(false);
        handleGetWithdrawHistory();
        setValueInput(0);
        dispatch(IsLoadingRedux(true));
      } else {
        toast.error(`${res.data.error.message}`);
        setCheckSign(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setCheckSign(false);
    }
  };

  useEffect(() => {
    if (signature && checkSign) {
      handleWithdraw();
    }
  }, [signature, checkSign]);

  useEffect(() => {
    if (selectData) {
      setValueInput(0);
    }
  }, [selectData]);

  return (
    <TransferContainer>
      <TransferHeader>
        <h2>WITHDRAW</h2>
      </TransferHeader>
      <FormBalance>
        <p>
          {selectData === "USDT" ? (
            <>
              {balancesDB?.balances
                ? convertFixed(Number(balancesDB?.balances[0]?.amount))
                : 0}
            </>
          ) : (
            <>
              {balancesDB?.balances
                ? convertFixed(Number(balancesDB?.balances[1]?.amount))
                : 0}
            </>
          )}
          <SelectCommon
            data={dataSelect}
            onChange={(e: any) => {
              setSelectData(e);
            }}
          />
        </p>
        <figure>
          <img
            width={19}
            height={18}
            src="/img/Common/wallet_icon.svg"
            alt="icon"
            loading="lazy"
          />
        </figure>
      </FormBalance>
      <FormInputs>
        <p>Withdraw Amount</p>
        <InputCommon
          value={valueInput}
          placeHolder="Enter Amount"
          suffix={<></>}
          onKeyDown={(e: any) => {
            if (["e", "E", "-", "+"].includes(e.key)) {
              e.preventDefault();
            }
          }}
          onPaste={(e: any) => {
            const pasteData = e.clipboardData.getData("text");
            if (!/^[0-9]*\.?[0-9]*$/.test(pasteData)) {
              e.preventDefault();
              toast.error("Please enter a valid positive amount", {
                id: "invalidPaste",
              });
            }
          }}
          onChange={(e: any) => {
            const value = e.target.value;
            const sanitizedValue = value.replace(/[^0-9.]/g, "");
            setValueInput(sanitizedValue);
          }}
        />
        <PerList>
          {dataPer.map((item, index) => {
            return (
              <li
                onClick={() => {
                  setPer(item.value);
                  if (selectData === "USDT") {
                    setValueInput(
                      (item?.value / 100) *
                        Number(balancesDB?.balances[0]?.amount)
                    );
                  } else {
                    setValueInput(
                      (item?.value / 100) *
                        Number(balancesDB?.balances[1]?.amount)
                    );
                  }
                }}
                className={per === item.value ? "active" : ""}
                key={index}
              >
                {item.value}%
              </li>
            );
          })}
        </PerList>
        <FormInfo>
          <div>
            <p>Estimate Fee</p>
            <p>5%</p>
          </div>
        </FormInfo>
        <ButtonForm
          disabled={isLoading}
          onClick={() => {
            handleApproveTransfer();
          }}
        >
          {isLoading ? <Spin /> : "Withdraw"}
        </ButtonForm>
      </FormInputs>
    </TransferContainer>
  );
};

const dataPer = [
  {
    id: 1,
    value: 25,
  },
  {
    id: 2,
    value: 50,
  },
  {
    id: 3,
    value: 75,
  },
  {
    id: 4,
    value: 100,
  },
];

export default Withdraw;
