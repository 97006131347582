import { useContext, useEffect, useState } from "react";
import BlockCommon from "../../components/Common/Block";
import { FormTabs } from "../../components/Common/Form/styled";
import FormVisual from "../../components/Common/Form/Visual";
import TableCommon from "../../components/Common/Table";
import TitleCommon from "../../components/Common/Title";
import HeaderCommon from "../../components/Header";
import {
  FormVisualIconDecor,
  WalletActions,
  WalletBlocks,
  WalletBody,
  WalletInfo,
  WalletTable,
} from "./styled";
import Withdraw from "./Withdraw";
import Transfer from "./Transfer";
import { AddressCopy, TagStatus, TokenValue } from "../../Layout/styled";
import { useSelector } from "react-redux";
import { convertFixed } from "../../utils/convertNumber";
import { PatrickAPI } from "../../services/api";
import { useAccount } from "wagmi";
import { shortenAddress } from "../../utils/addressUser";
import moment from "moment";
import { Link } from "react-router-dom";
import { ContextProviderWrapper } from "../../components/Context";
import { TableAction, TableInfo, TableMobile } from "../Dashboard/styled";

const Wallet = () => {
  const { address } = useAccount();
  const { balancesDB } = useSelector((state: any) => state.user);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const tableData = [
    {
      id: 1,
      address: "123",
      sponser: "123123",
      totalInvest: "12312312",
    },
    {
      id: 2,
      address: "123",
      sponser: "123123",
      totalInvest: "12312312",
    },
    {
      id: 3,
      address: "123",
      sponser: "123123",
      totalInvest: "12312312",
    },
    {
      id: 4,
      address: "123",
      sponser: "123123",
      totalInvest: "12312312",
    },
    {
      id: 5,
      address: "123",
      sponser: "123123",
      totalInvest: "12312312",
    },
    {
      id: 6,
      address: "123",
      sponser: "123123",
      totalInvest: "12312312",
    },
    {
      id: 7,
      address: "123",
      sponser: "123123",
      totalInvest: "12312312",
    },
    {
      id: 8,
      address: "123",
      sponser: "123123",
      totalInvest: "12312312",
    },
    {
      id: 9,
      address: "123",
      sponser: "123123",
      totalInvest: "12312312",
    },
    {
      id: 10,
      address: "123",
      sponser: "123123",
      totalInvest: "12312312",
    },
  ];

  const headingData = isMobile
    ? [
        {
          name: "",
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TableMobile>
                <TableInfo>
                  <p>
                    {data.coin === "USDT" ? (
                      <figure>
                        <img
                          width={21}
                          height={21}
                          src="/img/Common/usdt_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      </figure>
                    ) : (
                      <figure>
                        <img
                          src="/img/Common/ton_patrick_token.svg"
                          alt="icon"
                          loading="lazy"
                        />
                      </figure>
                    )}
                    {data.amount}
                  </p>
                  <div>
                    {data.transactionHash ? (
                      <AddressCopy>
                        <Link
                          to={`https://bscscan.com/tx/${data.transactionHash}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span
                            style={{
                              color: "#fff",
                            }}
                          >
                            Hash:{" "}
                          </span>
                          {shortenAddress(data.transactionHash, 5)}
                        </Link>
                      </AddressCopy>
                    ) : (
                      "--"
                    )}
                    <p>
                      Date: {moment(data.createdAt).format("D/M/YYYY HH:mm:ss")}
                    </p>
                  </div>
                </TableInfo>
                <TableAction
                  style={{
                    justifyContent: "center",
                    paddingTop: '5px'
                  }}
                >
                  <TagStatus
                    className="success"
                    style={{
                      margin: isMobile ? "0" : "0 auto",
                    }}
                  >
                    <figure>
                      <img
                        width={15}
                        height={16}
                        src="/img/Common/success_icon.svg"
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                    <p>{data.status}</p>
                  </TagStatus>
                </TableAction>
              </TableMobile>
            );
          },
        },
      ]
    : [
        {
          name: "ID Transaction",
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{shortenAddress(data._id, 4)}</p>;
          },
        },
        {
          name: `Amount`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TokenValue>
                {data.coin === "USDT" ? (
                  <figure>
                    <img
                      width={21}
                      height={21}
                      src="/img/Common/usdt_icon.png"
                      alt="icon"
                      loading="lazy"
                    />
                  </figure>
                ) : (
                  <figure>
                    <img
                      src="/img/Common/ton_patrick_token.svg"
                      alt="icon"
                      loading="lazy"
                    />
                  </figure>
                )}
                {convertFixed(Number(data.amount))}
              </TokenValue>
            );
          },
        },
        // {
        //   name: `Wallet`,
        //   dataIndex: "",
        //   key: "",
        //   render: (data: any) => {
        //     return (
        //       <AddressCopy>
        //         0xd3...12f9
        //         <figure>
        //           <img
        //             width={14}
        //             height={14}
        //             src="/img/Common/copy_2_icon.svg"
        //             alt="icon"
        //             loading="lazy"
        //           />
        //         </figure>
        //       </AddressCopy>
        //     );
        //   },
        // },
        {
          name: `Date`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{moment(data.createdAt).format("D/M/YYYY HH:mm:ss")}</p>;
          },
        },
        {
          name: `Transaction`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <AddressCopy>
                {data.transactionHash ? (
                  <>
                    <Link
                      to={`https://bscscan.com/tx/${data.transactionHash}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span
                        style={{
                          color: "#fff",
                        }}
                      ></span>
                      {shortenAddress(data.transactionHash, 5)}
                    </Link>
                  </>
                ) : (
                  "--"
                )}
              </AddressCopy>
            );
          },
        },
        {
          name: `Status`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TagStatus
                className="success"
                style={{
                  margin: "0 auto",
                }}
              >
                <figure>
                  <img
                    width={15}
                    height={16}
                    src="/img/Common/success_icon.svg"
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
                <p style={{ textTransform: "capitalize" }}>{data.status}</p>
              </TagStatus>
            );
          },
        },
      ];

  const [tab, setTab] = useState(1);
  const handleSwitchForm = () => {
    switch (tab) {
      case 1:
        return <Transfer />;
      case 2:
        return <Withdraw handleGetWithdrawHistory={handleGetWithdrawHistory} />;
    }
  };

  const handleGetWithdrawHistory = async () => {
    try {
      const res = await PatrickAPI.getWithdrawHistory(address);
      if (res.status === 200) {
        setWithdrawHistory(res.data.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address) {
      handleGetWithdrawHistory();
    }
  }, [address]);

  return (
    <div>
      <HeaderCommon title="your wallet" />
      <WalletBody>
        <WalletInfo>
          <WalletBlocks>
            <BlockCommon
              icon="/img/Common/ton_patrick_token.svg"
              title="TPT Balance"
              value={
                balancesDB?.balances
                  ? convertFixed(Number(balancesDB?.balances[1]?.amount))
                  : 0
              }
            />
            <BlockCommon
              icon="/img/Common/usdt_icon.png"
              title="USDT Balance"
              value={
                balancesDB?.balances
                  ? convertFixed(Number(balancesDB?.balances[0]?.amount))
                  : 0
              }
            />
          </WalletBlocks>
          <TitleCommon
            title="Withdraw History"
            desc="TON Patrick has been audited by top experts in blockchain security."
          />
          <WalletTable>
            <TableCommon data={withdrawHistory} heading={headingData} />
          </WalletTable>
        </WalletInfo>
        <WalletActions>
          <FormVisual
            content={
              <>
                <FormTabs>
                  {dataTabs.map((item, index) => {
                    return (
                      <div
                        className={item.id === tab ? "active" : ""}
                        key={index}
                        onClick={() => {
                          setTab(item.id);
                        }}
                      >
                        {item.text}
                      </div>
                    );
                  })}
                </FormTabs>
                <FormVisualIconDecor>
                  <img
                    width={87}
                    height={93}
                    src="/img/Common/formVisual_decor_icon_1.png"
                    alt="icon"
                    loading="lazy"
                  />
                </FormVisualIconDecor>
                {handleSwitchForm()}
              </>
            }
            textWarning="You can also turn this pop up off while confirm your transaction."
          />
        </WalletActions>
      </WalletBody>
    </div>
  );
};

const dataTabs = [
  {
    id: 1,
    text: "Transfer",
  },
  {
    id: 2,
    text: "Withdraw",
  },
];

export default Wallet;
