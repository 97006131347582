import styled from "styled-components";

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 10px;
  h2 {
    font-family: Bahnschrift;
    font-size: 24px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
  }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 20px;
    }
  }
`;
