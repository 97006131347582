import { useContext } from "react";
import CardCommon from "../../components/Common/Card";
import HeaderCommon from "../../components/Header";
import {
  RankingBanner,
  RankingDetail,
  RankingItem,
  RankingList,
  RankingTitle,
} from "./styled";
import { ContextProviderWrapper } from "../../components/Context";
import { useSelector } from "react-redux";

const Bonus = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const { userProfile } = useSelector((state: any) => state.user);

  const handleChangeLevel = () => {
    switch (userProfile?.rank) {
      case 1:
        return (
          <CardCommon
            icon="/img/Common/level_1.png"
            level="Miner"
            className="level_1"
            backgroundColor={["#A2FF6F", "#37FF48", "#00EA13"]}
          />
        );
      case 2:
        return (
          <CardCommon
            icon="/img/Common/level_2.png"
            level="Harvester"
            className="level_2"
            backgroundColor={["#6FF8FF", "#43E9FF", "#00D0FF"]}
          />
        );
      case 3:
        return (
          <CardCommon
            icon="/img/Common/level_3.png"
            level="Guardian"
            className="level_3"
            backgroundColor={["#6FF8FF", "#43E9FF", "#00D0FF"]}
          />
        );
      case 4:
        return (
          <CardCommon
            icon="/img/Common/level_4.png"
            level="Delegate"
            className="level_4"
            backgroundColor={["#CA6FFF", "#C343FF", "#B700FF"]}
          />
        );
      case 5:
        return (
          <CardCommon
            icon="/img/Common/level_5.png"
            level="Nominator"
            className="level_5"
            backgroundColor={["#FFFA6F", "#FFEC43", "#FFD400"]}
          />
        );
      case 6:
        return (
          <CardCommon
            icon="/img/Common/level_6.png"
            level="Validator"
            className="level_6"
            backgroundColor={["#FF6FE0", "#FF43CA", "#F0A"]}
          />
        );
      case 7:
        return (
          <CardCommon
            icon="/img/Common/level_7.png"
            level="Elite Validator"
            className="level_7"
            backgroundColor={["#FF6F72", "#FF4346", "#FF0004"]}
          />
        );
      default:
        return (
          <CardCommon
            icon="/img/Common/level_0.png"
            level="Member"
            className="level_0"
            backgroundColor={["#DCDCDC", "#C1C1C1", "#949494"]}
          />
        );
    }
  };

  return (
    <div>
      {!isMobile && <HeaderCommon title="ranking bonus" />}
      <RankingBanner>
        <figure>
          {isMobile ? (
            <img
              width={398}
              height={303}
              src="/img/Ranking/ranking_banner_sp.jpg"
              alt="icon"
              loading="lazy"
            />
          ) : (
            <img
              width={1132}
              height={238}
              src="/img/Ranking/ranking_banner.jpg"
              alt="icon"
              loading="lazy"
            />
          )}
        </figure>
        <RankingTitle>
          <h2>Discover our six packages</h2>
          <p>
            TON Patrick has been audited by top experts in blockchain security.
          </p>
        </RankingTitle>
      </RankingBanner>
      <RankingList>
        {handleChangeLevel()}
        {rankingData.map((item, index) => {
          return (
            <RankingItem key={index} className={`level_${item.id}`}>
              <figure>
                <img
                  width={146}
                  height={94}
                  src={item.icon}
                  alt="icon"
                  loading="lazy"
                />
              </figure>
              {item.id === 7 ? (
                <h2
                  style={{
                    color: item.color,
                  }}
                >
                  {item.name}
                </h2>
              ) : (
                <h2
                  style={{
                    color: item.color,
                  }}
                >
                  - {item.name} -
                </h2>
              )}
              <RankingDetail>
                <div>
                  <p>Miner Member</p>
                  <span>{item.member}</span>
                </div>
                <div>
                  <p>Your Invest Required</p>
                  <span>${item.investRequired}</span>
                </div>
                {item.id < 3 ? (
                  <></>
                ) : (
                  <div>
                    <p>Total Team Invest</p>
                    <span>${item.teamInvest}</span>
                  </div>
                )}
              </RankingDetail>
            </RankingItem>
          );
        })}
      </RankingList>
    </div>
  );
};

const rankingData = [
  {
    id: 1,
    icon: "/img/Common/level_1.png",
    name: "Miner",
    level: "1",
    member: "0",
    investRequired: "100",
    teamInvest: "0",
    color: "#5EC738",
  },
  {
    id: 2,
    icon: "/img/Common/level_2.png",
    name: "harvester",
    level: "2",
    member: "2",
    investRequired: "200",
    teamInvest: "0",
    color: "#5ED7FF",
  },
  {
    id: 3,
    icon: "/img/Common/level_3.png",
    name: "guardian",
    level: "3",
    member: "3",
    investRequired: "300",
    teamInvest: "2,000",
    color: "#65A5FF",
  },
  {
    id: 4,
    icon: "/img/Common/level_4.png",
    name: "delegate",
    level: "4",
    member: "4",
    investRequired: "500",
    teamInvest: "20,000",
    color: "#AD65FF",
  },
  {
    id: 5,
    icon: "/img/Common/level_5.png",
    name: "nominator",
    level: "5",
    member: "5",
    investRequired: "1,000",
    teamInvest: "50,000",
    color: "#FFD92E",
  },
  {
    id: 6,
    icon: "/img/Common/level_6.png",
    name: "validator",
    level: "6",
    member: "6",
    investRequired: "2,000",
    teamInvest: "200,000",
    color: "#DE88FF",
  },
  {
    id: 7,
    icon: "/img/Common/level_7.png",
    name: "elite validator",
    level: "7",
    member: "7",
    investRequired: "5,000",
    teamInvest: "1,000,000",
    color: "#FF888A",
  },
];

export default Bonus;
