import { useState } from "react";
import TitleLanding from "../../../components/Landing/Title";
import {
  FeaturesContainer,
  FeaturesContent,
  FeaturesInfo,
  FeaturesList,
} from "./styled";
import { ButtonCommon } from "../../../Layout/styled";
import { Link } from "react-router-dom";

const Features = () => {
  const [idClicked, setIdClicked] = useState(1);
  return (
    <FeaturesContainer id="features">
      <TitleLanding title="Features" subContent={<h3>Clearly!!!</h3>} />
      <FeaturesList>
        {dataFeatures.map((item, index) => {
          return (
            <li
              className={idClicked === item.id ? `active block_${item.id}` : `block_${item.id}`}
              onClick={() => {
                setIdClicked(item.id);
              }}
              key={index}
            >
              <p>{item.title}</p>
            </li>
          );
        })}
      </FeaturesList>
      <FeaturesContent
        className={
          dataFeatures[idClicked - 1].id === idClicked
            ? `block_${idClicked} active`
            : `block_${idClicked}`
        }
      >
        <figure className="image-content">
          <img
            width={500}
            height={295}
            src={dataFeatures[idClicked - 1]?.content.img}
            alt="icon"
            loading="lazy"
          />
        </figure>
        <FeaturesInfo>
          <h2>{dataFeatures[idClicked - 1]?.title}</h2>
          <p>{dataFeatures[idClicked - 1]?.content.description}</p>
          <ButtonCommon className="blue">
            <Link to="/">
              Launch App
              <img
                src="/img/Common/arrow_white.svg"
                alt="icon"
                loading="lazy"
              />
            </Link>
          </ButtonCommon>
        </FeaturesInfo>
      </FeaturesContent>
    </FeaturesContainer>
  );
};

const dataFeatures = [
  {
    id: 1,
    title: "Staking Nominator",
    content: {
      img: "/img/Landing/features_1.png",
      title: "Staking Nominator",
      description:
        "Users stake TON to Validators they trust, without directly participating in validation.",
      button: "Launch App",
    },
  },
  {
    id: 2,
    title: "Share Rewards",
    content: {
      img: "/img/Landing/features_2.png",
      title: "Share Rewards",
      description:
        "Nominator receives rewards from Validator according to the staked TON & TPT ratio.",
      button: "Launch App",
    },
  },
  {
    id: 3,
    title: "Select Validator",
    content: {
      img: "/img/Landing/features_3.png",
      title: "Select Validator",
      description:
        "The platform provides tools for Nominator to analyze and select Validators based on performance.",
      button: "Launch App",
    },
  },
  {
    id: 4,
    title: "Risk Management",
    content: {
      img: "/img/Landing/features_4.png",
      title: "Risk Management",
      description:
        "The platform provides tools for Nominator to analyze and select Validators based on performance.",
      button: "Launch App",
    },
  },
];

export default Features;
