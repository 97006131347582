import {
  ContentWarningContainer,
  WarningBody,
  WarningButtons,
  WarningHeader,
} from "../Warning/styled";
import { ButtonCommon } from "../../../../Layout/styled";
import InputCommon from "../../Input";
import { BodyRow } from "./styled";
import { PatrickAPI } from "../../../../services/api";
import { useAccount, useSignMessage } from "wagmi";
import { useEffect, useState } from "react";
import { FormBalance } from "../../../../page/Wallet/Transfer/styled";
import toast from "react-hot-toast";
import { Spin } from "antd";

const ContentSuccess = ({ handleCancel, Package }: any) => {
  const { address } = useAccount();
  const verifyMessage = "Claim";
  const { data: signature, signMessage } = useSignMessage();
  const [memo, setMemo] = useState("");
  const [tonAddress, setTonAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleError = (error: any) => {
    switch (error) {
      case "CAN_NOT_CLAIM":
        return toast.error("You cannot claim Ton");
      case "Invalid address":
        return toast.error("Invalid address");
      case "Invalid user":
        return toast.error("Invalid user");
      case "Invalid package":
        return toast.error("Invalid package");
      case "Can not withdraw":
        return toast.error("Can not withdraw");
      case "This coin is not support":
        return toast.error("This coin is not support");
      case "Not enough balance":
        return toast.error("Not enough balance");
      default:
        return toast.error("Claim error");
    }
  };

  const handleClaimTon = async () => {
    const params = {
      address: address,
      signature: signature,
      message: verifyMessage,
      toAddress: tonAddress,
      memo: memo,
      packageId: Package.id,
    };
    try {
      const res = await PatrickAPI.claimTon(params);
      console.log(res);
      if (res.data.success === true) {
        setIsLoading(false);
      } else {
        handleError(res.data.error.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Error");
    }
  };

  const handleVerifyWallet = async () => {
    if (tonAddress.length < 47 || tonAddress === "") {
      toast.error("Please check your Ton wallet again");
      return;
    }

    if (Package.amount === 0) {
      toast.error("You don't have any Ton to claim");
      return;
    }

    if (!address) {
      toast.error("Please connect wallet");
      return;
    }
    setIsLoading(true);
    signMessage({ message: verifyMessage });
  };

  useEffect(() => {
    if (signature) {
      handleClaimTon();
    }
  }, [signature]);

  return (
    <ContentWarningContainer>
      <WarningHeader>
        <figure>
          <img
            width={82}
            height={80}
            src="/img/Modal/logo_success.png"
            alt="icon"
            loading="lazy"
          />
        </figure>
        <h2>Claim your TON reward</h2>
      </WarningHeader>
      <FormBalance>
        <p>
          {Package.amount}
          <figure>
            <img
              width={21}
              height={21}
              src="/img/Common/ton_icon.svg"
              alt="icon"
              loading="lazy"
            />
          </figure>
        </p>
        <figure>
          <img
            width={19}
            height={18}
            src="/img/Common/wallet_icon.svg"
            alt="icon"
            loading="lazy"
          />
        </figure>
      </FormBalance>
      <WarningBody>
        <BodyRow>
          <p>TON Wallet Address</p>
          <InputCommon
            placeHolder="Enter your TON address"
            onChange={(e: any) => {
              setTonAddress(e.target.value);
            }}
          />
        </BodyRow>
        <BodyRow>
          <p>Memo (optional)</p>
          <InputCommon
            placeHolder="Enter memo"
            onChange={(e: any) => {
              setMemo(e.target.value);
            }}
          />
        </BodyRow>
      </WarningBody>
      <WarningButtons>
        <ButtonCommon
          className="warning"
          onClick={() => {
            handleCancel();
          }}
        >
          <p>Cancel</p>
        </ButtonCommon>
        <ButtonCommon
          className="success"
          onClick={() => {
            handleVerifyWallet();
          }}
        >
          <p>{isLoading ? <Spin /> : "Confirm"}</p>
        </ButtonCommon>
      </WarningButtons>
    </ContentWarningContainer>
  );
};

export default ContentSuccess;
