import { useEffect } from "react";
import { ButtonCommon } from "../../../../Layout/styled";
import {
  ContentWarningContainer,
  WarningBody,
  WarningButtons,
  WarningHeader,
} from "./styled";
import { useSignMessage } from "wagmi";

const ContentWarning = ({ handleCancel, functionProps }: any) => {
  const { data: signature, signMessage } = useSignMessage();
  const verifyMessage = "Cancel Mining";
  const handleApproveCancel = async () => {
    try {
      signMessage({ message: verifyMessage });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (signature) {
      functionProps(signature);
    }
  }, [signature]);

  return (
    <ContentWarningContainer>
      <WarningHeader>
        <figure>
          <img
            width={82}
            height={80}
            src="/img/Modal/logo_warning.png"
            alt="icon"
            loading="lazy"
          />
        </figure>
        <h2>Are You Sure?</h2>
      </WarningHeader>
      <WarningBody>
        <p>
          Your stake will be stop. If continue, you can just get back{" "}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            40% of amount
          </span>{" "}
          that you staked in before.
        </p>
      </WarningBody>
      <WarningButtons>
        <ButtonCommon
          onClick={() => {
            handleCancel();
          }}
        >
          <p>Cancel</p>
        </ButtonCommon>
        <ButtonCommon
          className="warning"
          onClick={() => {
            handleApproveCancel();
          }}
        >
          <p>Continue</p>
        </ButtonCommon>
      </WarningButtons>
    </ContentWarningContainer>
  );
};

export default ContentWarning;
