import { useEffect } from "react";
import FooterLanding from "../../components/Landing/Footer";
import HeaderLanding from "../../components/Landing/Header";
import Benefits from "./Benefits";
import Faqs from "./Faqs";
import Features from "./Features";
import Info from "./Info";
import Keyvisual from "./Keyvisual";
import Roadmap from "./Roadmap";
import Security from "./Security";
import Solutions from "./Solutions";
import Started from "./Started";
import { LandingContainer, LandingWrapper } from "./styled";

const Landing = () => {
  useEffect(() => {
    window.onbeforeunload = function () {
      localStorage.setItem("isMenu", "Home");
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <LandingContainer>
      <HeaderLanding />
      <Keyvisual />
      <LandingWrapper>
        <Info />
        <Solutions />
        <Benefits />
        <Features />
        <Started />
        <Security />
        <Roadmap />
        <Faqs />
      </LandingWrapper>
      <FooterLanding />
    </LandingContainer>
  );
};

export default Landing;
