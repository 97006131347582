import styled from "styled-components";
import { TitlePage } from "../../../components/Landing/Title/styled";

export const FeaturesContainer = styled.div`
  padding-top: 100px;
  ${TitlePage} {
    margin-bottom: 24px;
    text-align: center;
    flex-direction: column;
    gap: 0;
    h3 {
      color: #408cff;
      font-size: 32px;
      font-weight: 600;
      text-transform: capitalize;
      display: block;
    }
  }
  @media screen and (max-width: 1023px) {
    ${TitlePage} {
      align-items: center;
    }
  }
`;
export const FeaturesList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 100px;
  & > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 12px;
    background: #4b4b4b;
    box-shadow: 0px 1.182px 2.245px 0px #717171 inset,
      0px 3.545px 0px 0px #303030;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.15s linear;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -10px;
      margin-left: -6px;
      background: url("/img/Landing/mark_features.svg") no-repeat center / 100%
        auto;
      width: 14px;
      height: 15px;
    }
    &:hover,
    &.active {
      border-radius: 12px;
      border-color: #d1d1d1;
      background: #0554ff;
      box-shadow: 0px 1.182px 2.245px 0px #598bff inset,
        0px 3.545px 0px 0px #4079ff;
      p {
        color: #fff;
      }
      &:nth-child(even) {
        transform: rotate(5deg);
      }
      &:nth-child(odd) {
        transform: rotate(-5deg);
      }
    }
    p {
      color: #cbcbcb;
      font-size: 19px;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    margin-bottom: 30px;
    & > li {
      width: calc(100% / 2 - 45px);
      p {
        font-size: 15px;
      }
    }
  }
`;
export const FeaturesContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 0 40px;
  &::before {
    content: "";
    position: absolute;
    background: no-repeat center / 100% 100%;
    width: 100%;
    height: 205px;
    transition: all 0.15s linear;
    top: -100px;
    pointer-events: none;
  }
  &.block_1 {
    &::before {
      background-image: url("/img/Landing/feature_line_1.svg");
    }
    &.active {
      &::before {
        left: -11px;
      }
    }
  }
  &.block_2 {
    &::before {
      background-image: url("/img/Landing/feature_line_2.svg");
    }
    &.active {
      &::before {
        left: -5px;
      }
    }
  }
  &.block_3 {
    &::before {
      background-image: url("/img/Landing/feature_line_3.svg");
    }
    &.active {
      &::before {
        left: 6px;
      }
    }
  }
  &.block_4 {
    &::before {
      background-image: url("/img/Landing/feature_line_4.svg");
    }
    &.active {
      &::before {
        left: 8px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    padding: 0 15px;
    &:before {
      content: none !important;
    }
    .image-content {
      width: 100%;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;
export const FeaturesInfo = styled.div`
  h2 {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  p {
    color: rgba(255, 255, 255, 0.43);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1023px) {
    text-align: center;
    min-height: 163px;
  }
`;
