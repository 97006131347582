import { Modal } from "antd";

const StakingConfirm = ({
  isModalOpen,
  handleOk,
  handleCancel,
  isSuccess,
  isContent,
}: any) => {
  return (
    <Modal
      className={isSuccess ? "modal-success" : "modal-warning"}
      footer={false}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {isContent}
    </Modal>
  );
};

export default StakingConfirm;
