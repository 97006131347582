import { useContext } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import {
  KeyvisualContainer,
  KeyvisualDescription,
  KeyvisualWrapper,
  TextBrand,
} from "./styled";
import { TypeAnimation } from "react-type-animation";

const Keyvisual = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <KeyvisualContainer>
      <KeyvisualWrapper>
        <h2>
          <TypeAnimation
            sequence={[
              "Mining The Future",
              3000,
              "Attractive Rewards",
              3000,
              "Join Easily",
              3000,
              "Outstanding Security",
              3000,
            ]}
            wrapper="span"
            speed={50}
            style={{ display: "inline-block" }}
            repeat={Infinity}
            className="type-animation"
          />
        </h2>
        <p>
          with{" "}
          <TextBrand>
            <img
              width={215}
              height={68}
              src={`${
                isDesktop
                  ? "/img/Landing/logo_landing.svg"
                  : "/img/Landing/Keyvisual/logo_landing_sp.png"
              }`}
              alt="icon"
              loading="eager"
            />
          </TextBrand>
          on
        </p>
        <p>The Open Network</p>
        <KeyvisualDescription>
          <p>
            TON Patrick is a mining solution developed on the TON Blockchain
            platform to
            <br className="md" /> provide an efficient, fast and secure mining
            system.
          </p>
        </KeyvisualDescription>
      </KeyvisualWrapper>
    </KeyvisualContainer>
  );
};

export default Keyvisual;
