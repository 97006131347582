import styled from "styled-components";

export const AppContainer = styled.div`
  min-height: 100vh;
  color: #fff;
  background-color: #000;
  @media (max-width: 1023px) {
    overflow-x: hidden;
  }
`;
