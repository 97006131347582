import { useContext, useEffect, useState } from "react";
import { PatrickAPI } from "../../services/api";
import { useAccount } from "wagmi";
import TableCommon from "../Common/Table";
import { AddressCopy, ButtonAction, ButtonCopy } from "../../Layout/styled";
import { shortenAddress } from "../../utils/addressUser";
import { convertFixed } from "../../utils/convertNumber";
import moment from "moment";
import { ContextProviderWrapper } from "../Context";
import {
  TableAction,
  TableInfo,
  TableMobile,
} from "../../page/Dashboard/styled";
import ProgressCommon from "../Common/Progress";
import { Link } from "react-router-dom";
import StakingConfirm from "../../page/Staking/Confirm";
import toast from "react-hot-toast";
import ContentWarning from "../Common/Modal/Warning";
import ContentSuccess from "../Common/Modal/Success";

const TableMining = () => {
  const { address } = useAccount();
  const [tableInvest, setTableInvest] = useState([]);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [transactionId, setTransactionId] = useState("");
  const [Package, setPackage] = useState({
    id: 0,
    amount: 0,
  });
  const headingData = isMobile
    ? [
        {
          name: "",
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TableMobile>
                <TableInfo>
                  <p>${convertFixed(Number(data.amount))}</p>
                  <AddressCopy
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    Ton reward:
                    <p>
                      {data.amountTon
                        ? convertFixed(Number(data.amountTon))
                        : 0}{" "}
                      <span
                        style={{
                          color: "#0073FF",
                        }}
                      >
                        TON
                      </span>
                      {Number(data.currentAmountTon) > 0 && (
                        <ButtonCopy
                          onClick={() => {
                            setPackage((prev: any) => ({
                              ...prev,
                              id: data._id,
                              amount: data.currentAmountTon,
                            }));
                            setIsModalOpenTon(true);
                          }}
                        >
                          Claim
                        </ButtonCopy>
                      )}
                    </p>
                  </AddressCopy>
                  <div>
                    <AddressCopy>
                      <Link
                        to={`https://bscscan.com/tx/${data.transactionHash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span
                          style={{
                            color: "#fff",
                          }}
                        >
                          Hash:{" "}
                        </span>
                        {shortenAddress(data.transactionHash, 5)}
                      </Link>
                    </AddressCopy>
                    <p>{moment(data.createdAt).format("D/M/YYYY HH:mm:ss")}</p>
                  </div>
                </TableInfo>
                <TableAction>
                  <ProgressCommon
                    content={`${convertFixed(
                      (data.currentAmountUsdt / data.finalAmountUsdt) * 300
                    )}%`}
                    progress={
                      isFinite(
                        (data.currentAmountUsdt / data.finalAmountUsdt) * 300
                      ) && data.finalAmountUsdt !== 0
                        ? (data.currentAmountUsdt / data.finalAmountUsdt) * 300
                        : 0
                    }
                    backgroundColor={"#6F91FF"}
                  />
                  {data.isCanceled ? (
                    <ButtonAction
                      style={{ margin: "0 0 auto 0" }}
                      className="warning"
                    >
                      Stopped
                    </ButtonAction>
                  ) : (
                    <ButtonAction
                      style={{ margin: "0 0 auto 0" }}
                      className="error"
                      onClick={() => {
                        setIsModalOpen(true);
                        setTransactionId(data._id);
                      }}
                    >
                      Stop Mining
                      <figure>
                        <img
                          width={16}
                          height={16}
                          src="/img/Common/unlock_icon.svg"
                          alt="icon"
                          loading="lazy"
                        />
                      </figure>
                    </ButtonAction>
                  )}
                </TableAction>
              </TableMobile>
            );
          },
        },
      ]
    : [
        {
          name: "ID",
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{shortenAddress(data._id, 5)}</p>;
          },
        },
        {
          name: `Amount`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>${convertFixed(Number(data.amount))}</p>;
          },
        },
        {
          name: `TON Reward`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <p>
                  {data.amountTon ? convertFixed(Number(data.amountTon)) : 0}{" "}
                  <span
                    style={{
                      color: "#0073FF",
                    }}
                  >
                    TON
                  </span>
                </p>
                {Number(data.currentAmountTon) > 0 && (
                  <ButtonCopy
                    onClick={() => {
                      setPackage((prev: any) => ({
                        ...prev,
                        id: data._id,
                        amount: data.currentAmountTon,
                      }));
                      setIsModalOpenTon(true);
                    }}
                  >
                    Claim
                  </ButtonCopy>
                )}
              </div>
            );
          },
        },
        {
          name: `Daily Mining`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <>
                {isMobile && "Daily Mining: "}
                <ProgressCommon
                  content={`${convertFixed(
                    (data.currentAmountUsdt / data.finalAmountUsdt) * 300
                  )}%`}
                  progress={
                    isFinite(
                      (data.currentAmountUsdt / data.finalAmountUsdt) * 300
                    ) && data.finalAmountUsdt !== 0
                      ? (data.currentAmountUsdt / data.finalAmountUsdt) * 300
                      : 0
                  }
                  backgroundColor={"#6F91FF"}
                />
              </>
            );
          },
        },
        {
          name: `Start Date`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{moment(data.createdAt).format("D/M/YYYY HH:mm:ss")}</p>;
          },
        },
        {
          name: `Hash`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <Link
                to={`https://bscscan.com/tx/${data.transactionHash}`}
                target="_blank"
                rel="noreferrer"
              >
                {shortenAddress(data.transactionHash, 5)}
              </Link>
            );
          },
        },
        {
          name: `Action`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <>
                {data.isCanceled ? (
                  <ButtonAction className="warning">Stopped</ButtonAction>
                ) : (
                  <ButtonAction
                    className="error"
                    onClick={() => {
                      setIsModalOpen(true);
                      setTransactionId(data._id);
                    }}
                  >
                    Stop Mining
                    <figure>
                      <img
                        width={16}
                        height={16}
                        src="/img/Common/unlock_icon.svg"
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                  </ButtonAction>
                )}
              </>
            );
          },
        },
      ];

  const handleGetTableMining = async () => {
    try {
      const res = await PatrickAPI.investHistory(address);
      if (res.status === 200 && address) {
        setTableInvest(res.data.data.packages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address) {
      handleGetTableMining();
    }
  }, [address]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpenTon, setIsModalOpenTon] = useState(false);

  const handleOkTon = () => {
    setIsModalOpenTon(false);
  };

  const handleCancelTon = () => {
    setIsModalOpenTon(false);
  };

  const handleCancelStaking = async (signature: any) => {
    const params = {
      id: transactionId,
      address: address,
      signature: signature,
      message: "Cancel Mining",
    };
    try {
      const res = await PatrickAPI.cancelMining(params);
      if (res.status === 200) {
        handleGetTableMining();
        handleCancel();
        toast.success("Cancel successfully", { id: "cancel" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <StakingConfirm
        isModalOpen={isModalOpenTon}
        handleOk={handleOkTon}
        handleCancel={handleCancelTon}
        isSuccess={true}
        isContent={
          <ContentSuccess handleCancel={handleCancelTon} Package={Package} />
        }
      />
      <StakingConfirm
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isContent={
          <ContentWarning
            handleCancel={handleCancel}
            functionProps={handleCancelStaking}
          />
        }
      />
      <TableCommon data={tableInvest} heading={headingData} />
    </>
  );
};

export default TableMining;
