import { FormVisualContainer, FormVisualWarning } from "./styled";

const FormVisual = ({ content, textWarning }: any) => {
  return (
    <FormVisualContainer>
      {content}
      <FormVisualWarning>
        <figure>
          <img
            width={14}
            height={14}
            src="/img/Common/hint_icon.svg"
            alt="icon"
            loading="lazy"
          />
        </figure>
        {textWarning}
      </FormVisualWarning>
    </FormVisualContainer>
  );
};

export default FormVisual;
