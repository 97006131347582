import styled from "styled-components";

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  background: #3f3f3f;
  padding: 16px 12px;
  transition: all 0.15s linear;
  cursor: pointer;
  &:hover {
    background-color: #464646;
  }
`;
export const BlockHeader = styled.div`
  margin-bottom: 12px;
  figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(248, 248, 248, 0.05);
    background-blend-mode: luminosity;
    filter: drop-shadow(0px 6.4px 16px rgba(0, 0, 0, 0.3));
    backdrop-filter: blur(10px);
  }
  @media screen and (max-width: 767px) {
    figure {
      width: 32px;
      height: 32px;
    }
  }
`;
export const BlockContent = styled.div`
  & > h2 {
    background: linear-gradient(90deg, #8ebdff 0%, #fff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 8px;
  }
  & > p {
    font-size: 22px;
    font-weight: 500;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    & > h2 {
      font-size: 14px;
    }
    & > p {
      font-size: 18px;
    }
  }
`;
