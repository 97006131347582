import { SidebarList } from "../styled";
import { NavLink } from "react-router-dom";

const Navigation = ({ setMenuOpen }: any) => {
  return (
    <SidebarList>
      {sidebarList.map((item, index) => {
        return (
          <li
            onClick={() => {
              setMenuOpen(false);
            }}
            key={index}
          >
            <NavLink to={item.href}>
              <div>
                <figure>
                  <img
                    width={24}
                    height={24}
                    src={item.icon}
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
                <p>{item.text}</p>
              </div>
            </NavLink>
          </li>
        );
      })}
    </SidebarList>
  );
};

const sidebarList = [
  {
    id: 1,
    href: "/dashboard",
    text: "Dashboard",
    icon: "/img/Sidebar/dashboard_icon.png",
  },
  {
    id: 2,
    href: "/mining",
    text: "Mining",
    icon: "/img/Sidebar/mining_icon.png",
  },
  {
    id: 3,
    href: "/network",
    text: "Network",
    icon: "/img/Sidebar/network_icon.png",
  },
  {
    id: 4,
    href: "/bonus",
    text: "Ranking Bonus",
    icon: "/img/Sidebar/ranking_icon.png",
  },
  {
    id: 5,
    href: "/wallet",
    text: "Wallet",
    icon: "/img/Sidebar/wallet_icon.png",
  },
  {
    id: 6,
    href: "/history",
    text: "History",
    icon: "/img/Sidebar/history_icon.png",
  },
];

export default Navigation;
