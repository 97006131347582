import TitleLanding from "../../../components/Landing/Title";
import { BenefitsHeader } from "../Benefits/styled";
import {
  RoadmapContainer,
  RoadmapContentSlide,
  RoadmapSlide,
  RoadmapSlideItem,
  RoadmapSlideItemInner,
} from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useContext, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
interface sliderInterface {
  id: number;
  title: string;
  subTitle: string;
  subList: string[];
}
const Roadmap = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [activeIndex, setActiveIndex] = useState(0);
  const handleSlideChange = (e: any) => {
    setActiveIndex(e.realIndex + 1);
  };

  return (
    <RoadmapContainer className="roadmap-container" id="roadmap">
      <BenefitsHeader>
        <TitleLanding title="Roadmap" />
        <p>Ton Patrick is secure to the core</p>
      </BenefitsHeader>
      <RoadmapContentSlide>
        <RoadmapSlide activeIndex={activeIndex}>
          <Swiper
            spaceBetween={10}
            loop={true}
            slidesPerView={isDesktop ? 4 : 2}
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={{
              type: "progressbar",
            }}
            onSlideChange={handleSlideChange}
            className={activeIndex === 1 ? "first" : ""}
          >
            <RoadmapSlideItem>
              {listSlider.map((item: sliderInterface, index: number) => (
                <SwiperSlide className="item-slide" key={index}>
                  <RoadmapSlideItemInner>
                    <h3 className="item-title">{item.title}</h3>
                    <div className="block-content">
                      <h4 className="item-sub-title">{item.subTitle}</h4>
                      <ul className="item-list">
                        {item.subList.map((item: string, index: number) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </RoadmapSlideItemInner>
                </SwiperSlide>
              ))}
            </RoadmapSlideItem>
          </Swiper>
        </RoadmapSlide>
      </RoadmapContentSlide>
    </RoadmapContainer>
  );
};

const listSlider = [
  {
    id: 1,
    title: "Phase 01",
    subTitle: "Build & Foundation",
    subList: [
      "Research & Planing",
      "Build Tokenomics",
      "MVP Develope",
      "Launching Website",
    ],
  },
  {
    id: 2,
    title: "Phase 02",
    subTitle: "Building",
    subList: [
      "Build TON Patrick Platform",
      "Mining Program",
      "Marketing & Airdrop",
      "Build Community",
    ],
  },
  {
    id: 3,
    title: "Phase 03",
    subTitle: "Platform Development",
    subList: [
      "TON Patrick Testnet",
      "Integrate DEX Aggregator",
      "Integrate Validator",
      "Get Nominator Pool",
    ],
  },
  {
    id: 4,
    title: "Phase 04",
    subTitle: "Release Mainnet",
    subList: [
      "TON Patrick Mainnet",
      "Mining Analyst",
      "Public Sale",
      "Mining Pool",
      "Audit & Security",
      "Listing CEX/DEX",
    ],
  },
  {
    id: 5,
    title: "Phase 05",
    subTitle: "Maintainance",
    subList: [
      "Integrate DeFi",
      "Expand Liquidity",
      "Update UI/UX",
      "Optimize Security",
    ],
  },
];

export default Roadmap;
