import styled from "styled-components";

export const BodyRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  p {
    text-align: left;
    color: #1b1b1b;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;
