import TitleLanding from "../../../components/Landing/Title";
import { FaqsContainer, FaqsList } from "./styled";
import Accordion from "./Accordion";

const Faqs = () => {
  return (
    <FaqsContainer id="faqs">
      <TitleLanding title="FAQs" subContent={<h3>Stay clearly</h3>} />
      <FaqsList>
        {faqData.map((item) => {
          return <Accordion item={item} />;
        })}
      </FaqsList>
    </FaqsContainer>
  );
};

const faqData = [
  {
    id: 1,
    title: "What is TON Patrick?",
    description:
      "TON Patrick is a mining platform on the TON Blockchain, allowing users to participate in mining and staking the TPT token to earn rewards while supporting the activities of Validators and Nominators.",
  },
  {
    id: 2,
    title: "How can I earn profits by participating in TON Patrick?",
    description:
      "Users can earn profits by staking their TPT tokens to receive rewards from mining. Additionally, participating in staking pools and farming can help optimize returns.",
  },
  {
    id: 3,
    title: "How do I get started with TON Patrick?",
    description:
      "To get started, you need to create a TON wallet, purchase TPT tokens through exchanges, and then stake the tokens on the TON Patrick platform to begin earning rewards.",
  },
  {
    id: 4,
    title: "What risks are associated with participating in TON Patrick?",
    description:
      "As with any blockchain project, there are risks related to token price volatility, security, and technical issues. Users should conduct thorough research and only invest amounts they can afford to lose.",
  },
  {
    id: 5,
    title: "Where can I track my statistics and performance?",
    description:
      "Users can track their statistics and staking performance directly on the TON Patrick platform, which provides detailed information about the amount of tokens staked, rewards earned, and the performance of Validators.",
  },
];

export default Faqs;
