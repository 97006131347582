import { instance } from "../instance";

export const PatrickAPI = {
  verify(param: any) {
    return instance.post(`user/verify`, param);
  },
  getRef(param: any) {
    return instance.get(`user/referral-id?address=${param}`);
  },
  getTokenInfo() {
    return instance.get(`fund/token-info`);
  },
  getInfo(param: any) {
    return instance.get(`user/info?address=${param}`);
  },
  getNetwork(param: any) {
    return instance.post(`user/network`, param);
  },
  getBalances(param: any) {
    return instance.get(`fund/balance?address=${param}`);
  },
  getWithdrawHistory(param: any) {
    return instance.get(`fund/withdraw-history?address=${param}`);
  },
  getRevenueNetwork(param: any) {
    return instance.get(`user/total-sale?address=${param}`);
  },
  getCommissionNetwork(param: any) {
    return instance.get(`user/total-commission?address=${param}`);
  },
  getMemberNetwork(param: any) {
    return instance.get(`user/total-member?address=${param}`);
  },
  getDirectCommission(param: any) {
    return instance.get(`user/direct-commission?address=${param}`);
  },
  getDailyEarning(param: any) {
    return instance.get(`user/total-earning?address=${param}`);
  },
  getGrowthCommission(param: any) {
    return instance.get(`user/indirect-commission?address=${param}`);
  },
  getTotalNetwork(param: any) {
    return instance.post(`user/total-network`, param);
  },
  getTotalInvest(param: any) {
    return instance.get(`user/total-invest?address=${param}`);
  },
  investHistory(param: any) {
    return instance.get(`invest/get-package?address=${param}`);
  },
  getHistory(param: any) {
    return instance.post(`fund/balance-history`, param);
  },
  transfer(param: any) {
    return instance.post(`fund/transfer`, param);
  },
  withdraw(param: any) {
    return instance.post(`fund/withdraw`, param);
  },
  cancelMining(param: any) {
    return instance.post(`invest/cancel-invest`, param);
  },
  claimTon(param: any) {
    return instance.post(`fund/claim-ton`, param);
  }
};
