import styled from "styled-components";
import { InputContainer } from "../../components/Common/Input/styled";
import { SelectContainer } from "../../components/Common/Select/styled";

export const HistoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 12px;
  & > h2 {
    color: #fff;
    font-family: Bahnschrift;
    font-size: 24px;
    font-weight: 700;
  }
  @media screen and (max-width: 767px) {
    display: block;
    text-align: center;
    & > h2 {
      margin-bottom: 10px;
    }
  }
`;
export const HistoryFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${InputContainer} {
    width: 292px;
  }
  ${SelectContainer} {
    width: 200px;
  }
`;
export const HistoryTable = styled.div``;
