import { Link } from "react-router-dom";
import { SidebarContainer, SidebarInner, SidebarLogo } from "./styled";
import ConnectWallet from "./ConnectWallet";
import Navigation from "./Navigation";
import { useState } from "react";

const Sidebar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <SidebarContainer>
      <SidebarInner>
        <SidebarLogo>
          <Link to="/">
            <img
              width={75}
              height={20}
              src="/img/Common/logo.png"
              alt="icon"
              loading="lazy"
            />
          </Link>
        </SidebarLogo>
        <Navigation setMenuOpen={setMenuOpen} />
      </SidebarInner>
      <ConnectWallet />
    </SidebarContainer>
  );
};

export default Sidebar;
