import styled from "styled-components";
import {
  CardContainer,
  CardWrapper,
} from "../../components/Common/Card/styled";

export const RankingBanner = styled.div`
  position: relative;
  width: 100%;
  min-height: 238px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
  figure {
    img {
      width: 100%;
      height: auto;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    min-height: auto;
  }
`;
export const RankingTitle = styled.div`
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  text-align: center;
  h2 {
    font-family: Bahnschrift;
    font-size: 36px;
    font-weight: 700;
  }
  p {
    font-size: 15px;
    font-weight: 500;
  }
  @media screen and (max-width: 1023px) {
    width: calc(100% - 30px);
    top: 60px;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }
`;
export const RankingList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  ${CardWrapper} {
    width: calc(25% - 12px);
  }
  ${CardContainer} {
    width: auto;
  }
  @media screen and (max-width: 1023px) {
    flex-direction: row;
    overflow-x: auto;
    flex-wrap: unset;
    ${CardWrapper} {
      min-width: 310px;
      width: auto;
    }
  }
`;
export const RankingItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 275px;
  border-radius: 8px;
  background: #333;
  padding: 20px 12px;
  width: calc(25% - 33px);
  overflow: hidden;
  & > figure {
    width: 146px;
    margin: 0 auto 30px;
  }
  h2 {
    color: #797979;
    text-align: center;
    font-family: Bahnschrift;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.4;
    margin-bottom: 30px;
  }
  &::before {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    width: 242px;
    height: 190px;
    top: -12px;
    left: 50%;
    margin-left: -121px;
    mix-blend-mode: luminosity;
    opacity: 0.7;
  }
  &.level_1 {
    &::before {
      background-image: url("/img/Ranking/ranking_decor_1.png");
    }
  }
  &.level_2 {
    &::before {
      background-image: url("/img/Ranking/ranking_decor_2.png");
    }
  }
  &.level_3 {
    &::before {
      background-image: url("/img/Ranking/ranking_decor_3.png");
    }
  }
  &.level_4 {
    &::before {
      background-image: url("/img/Ranking/ranking_decor_4.png");
    }
  }
  &.level_5 {
    &::before {
      background-image: url("/img/Ranking/ranking_decor_5.png");
    }
  }
  &.level_6 {
    &::before {
      background-image: url("/img/Ranking/ranking_decor_6.png");
    }
  }
  &.level_7 {
    &::before {
      background-image: url("/img/Ranking/ranking_decor_7.png");
    }
  }
  @media screen and (max-width: 1023px) {
    min-width: 246px;
    width: auto;
  }
`;
export const RankingDetail = styled.div`
  & > div {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    p {
      color: rgba(226, 232, 255, 0.7);
      font-size: 13px;
    }
    span {
      text-align: right;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;
