import styled from "styled-components";
import { FooterInfo, FooterSocials } from "../Footer/styled";
import { ButtonCommon } from "../../../Layout/styled";

export const HeaderLandingContainer = styled.div`
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1120px;
  width: 100%;
  z-index: 100;
`;
export const HeaderLandingWrapper = styled.div`
  padding: 14px;
  border-radius: 12px;
  background: url("/img/Landing/nav_bg.png") no-repeat center / cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > a {
    display: block;
    width: max-content;
    height: 36px;
  }
  @media screen and (max-width: 1023px) {
    position: relative;
  }
`;
export const LandingNavigationContent = styled.div`
  @media screen and (max-width: 1023px) {
    position: fixed;
    top: 72px;
    left: 0;
    width: calc(100% - 28px);
    height: 100svh;
    background: #121212;
    z-index: 10;
    align-items: flex-start;
    padding: 20px 14px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.26s ease;
    overflow: auto;
    ${FooterInfo} {
      margin-top: 30px;
      border-top: solid 1px #282828;
      padding-top: 30px;
    }
    ${FooterSocials} {
      padding-top: 0;
      border-top: unset;
    }
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;
export const LandingNavigation = styled.ul`
  display: flex;
  align-items: center;
  gap: 14px;
  & > li {
    a {
      display: block;
      color: #b4b4b4;
      font-size: 15px;
      letter-spacing: 0.28px;
      transition: all 0.15s linear;
      cursor: pointer;
      &:hover {
        color: #fff;
        text-shadow: 0px 0px 18.1px #fff;
        font-weight: bold;
      }
      &.active {
        color: #fff;
        text-shadow: 0px 0px 18.1px #fff;
        font-weight: bold;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    & > li {
      width: 100%;
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #6d6d6d;
        font-size: 18px;
        padding: 12px 10px;
        &.active {
          color: #fff;
          font-weight: 600;
          border-radius: 8px;
          background: #282828;
          .icon-menu-active {
            opacity: 1;
          }
          .icon-menu-normal {
            opacity: 0;
          }
          .check-active {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
    .content-left {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .content-image {
      position: relative;
      width: 20px;
      height: 20px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.26s linear;
      }
      .icon-menu-normal {
        opacity: 1;
      }
      .icon-menu-active {
        opacity: 0;
      }
    }
    .check-active {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #539dff;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.28px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.26s ease;
    }
  }
`;

export const ButtonMenu = styled.button`
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -20.5px;
  right: 170px;
  -webkit-tap-highlight-color: transparent;
  width: 41px;
  height: 41px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.26s linear;
  }
  .icon-menu-normal {
    opacity: 1;
  }
  .icon-menu-active {
    opacity: 0;
  }
  &.active {
    .icon-menu-normal {
      opacity: 0;
    }
    .icon-menu-active {
      opacity: 1;
    }
  }
`;
