import { createWeb3Modal } from "@web3modal/wagmi/react";
import { WagmiProvider } from "wagmi";
import { AppContainer } from "./styled";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProvider } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { bsc } from "viem/chains";
import { useEffect } from "react";
import Dashboard from "./page/Dashboard";
import DashboardWrapper from "./Layout/Dashboard";
import Network from "./page/Network";
import Landing from "./page/Landing";
import Bonus from "./page/Bonus";
import Wallet from "./page/Wallet";
import History from "./page/History";
import Staking from "./page/Staking";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  const queryClient = new QueryClient();
  const projectId = "17c32b7d53c43402b9413971337ef0b2";
  const metadata = {
    name: "Ton Patrick",
    description: "Ton Patrick",
    url: "https://web3modal.com",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
  };

  const chains = [bsc] as const;
  const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
  });

  createWeb3Modal({
    wagmiConfig: config,
    projectId,
  });

  return (
    <Provider store={store}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <ContextProvider>
            <Toaster position="top-right" reverseOrder={false} />
            <AppContainer>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route element={<DashboardWrapper />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/ref/:ref" element={<Network />} />
                  <Route path="/mining" element={<Staking />} />
                  <Route path="/network" element={<Network />} />
                  <Route path="/bonus" element={<Bonus />} />
                  <Route path="/wallet" element={<Wallet />} />
                  <Route path="/history" element={<History />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </AppContainer>
          </ContextProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </Provider>
  );
};

export default App;
