import styled from "styled-components";
import { InputContainer } from "../../../components/Common/Input/styled";
import { SelectContainer } from "../../../components/Common/Select/styled";

export const TransferContainer = styled.div`
  margin: auto 0;
`;
export const TransferHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
  span {
    display: block;
    color: #323232;
    font-size: 14px;
    margin-bottom: 5px;
  }
  h2 {
    font-size: 24px;
    color: #323232;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  p {
    font-size: 12px;
    color: #2d2d2d;
  }
`;
export const FormBalance = styled.div`
  border-radius: 11px;
  background: #d1e4ff;
  padding: 5px;
  display: flex;
  align-items: center;
  width: max-content;
  margin: 0 auto 20px;
  gap: 5px;
  ${SelectContainer} {
    position: relative;
    left: 10px;
    width: 100px;
    .ant-select {
      background-color: transparent;
      height: 37px;
      .ant-select-selector {
        padding: 0;
      }
    }
  }
  & > p {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px;
    border-radius: 9px;
    background: #70a0fb;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    figure {
      position: relative;
      top: -1px;
      width: 21px;
      height: 21px;
    }
  }
  & > figure {
    width: 19px;
    height: 18px;
    cursor: pointer;
  }
`;
export const FormInputs = styled.div`
  margin-bottom: 10px;
  & > p {
    color: #1b1b1b;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  ${InputContainer} {
    .ant-input-affix-wrapper {
      background: rgba(41, 41, 41, 0.5);
    }
    .ant-input,
    .ant-input:focus,
    .ant-input:hover {
      background: unset;
    }
  }
`;
export const TextMax = styled.div`
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(248, 248, 248, 0.05);
  background-blend-mode: luminosity;
`;
export const PerList = styled.ul`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 5px;
  margin-bottom: 10px;
  & > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding: 6px 10px;
    border-radius: 8px;
    background: rgba(41, 41, 41, 0.5);
    cursor: pointer;
    transition: all 0.15s linear;
    &:hover,
    &.active {
      background-color: #fff;
      color: #000;
    }
  }
`;
export const ButtonForm = styled.button`
  width: 100%;
  padding: 8px 12px;
  min-height: 45px;
  border-radius: 8px;
  background: #fff;
  transition: all 0.15s linear;
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
