import TitleLanding from "../../../components/Landing/Title";
import { SolutionContainer, SolutionList, SolutionTitle } from "./styled";

const Solutions = () => {
  return (
    <SolutionContainer>
      <TitleLanding
        title="The Decentralized Solution for Liquidity Mining on"
        subContent={
          <SolutionTitle>
            <img
              width={84}
              height={29}
              src="/img/Landing/solution_title_decor.png"
              alt="icon"
              loading="lazy"
            />
          </SolutionTitle>
        }
      />
      <SolutionList>
        <li>
          <figure>
            <img
              width={24}
              height={24}
              src="/img/Common/arrow_white.svg"
              alt="icon"
              loading="lazy"
            />
          </figure>
          <p>
            TON Patrick provides a liquidity mining solution, making it easy for
            users to participate without the need for complex hardware.
          </p>
        </li>
        <li>
          <figure>
            <img
              width={24}
              height={24}
              src="/img/Common/arrow_white.svg"
              alt="icon"
              loading="lazy"
            />
          </figure>
          <p>
            The system automatically optimizes the mining process, ensuring high
            performance and stable rewards with $TPT
          </p>
        </li>
        <li>
          <figure>
            <img
              width={24}
              height={24}
              src="/img/Common/arrow_white.svg"
              alt="icon"
              loading="lazy"
            />
          </figure>
          <p>
            Liquidity mining on TON Patrick is safe and transparent thanks to
            the decentralized platform of TON Blockchain.
          </p>
        </li>
      </SolutionList>
    </SolutionContainer>
  );
};

export default Solutions;
