import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #000;
`;
export const FooterWrapper = styled.div`
  max-width: 1120px;
  width: 100%;
  padding: 40px 20px 30px;
  margin: 0 auto;
  @media screen and (max-width: 1023px) {
    width: unset;
    padding: 20px 15px;
  }
`;
export const FooterInfo = styled.div`
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;
export const FooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;
export const FooterNavigation = styled.ul`
  display: flex;
  gap: 40px;
  & > li {
    & > h2 {
      position: relative;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      padding-left: 12px;
      margin-bottom: 15px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 6px;
        height: 6px;
        background: url("/img/Landing/rectangle_title.svg") no-repeat center /
          100% auto;
      }
    }
    & > ul {
      & > li {
        &:not(:last-child) {
          margin-bottom: 7px;
        }
        a {
          color: rgba(255, 255, 255, 0.6);
          font-size: 15px;
          font-weight: 400;
          transition: all 0.15s linear;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    & > li {
      &:nth-child(1) {
        width: 22%;
      }
      &:nth-child(2) {
        width: 39%;
      }
      &:nth-child(3) {
        width: 28%;
      }
      & > h2 {
        font-size: 13px;
        padding-left: 8px;
      }
      & > ul {
        & > li {
          a {
            font-size: 13px;
          }
        }
      }
    }
  }
`;
export const FooterLogo = styled(Link)`
  width: max-content;
  display: block;
  margin-bottom: 10px;
  height: 36px;
`;
export const FooterDescription = styled.p`
  color: #818181;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 20px;
`;
export const FooterSocials = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 250px;
  & > li {
    a {
      display: flex;
      align-items: center;
      width: max-content;
      gap: 5px;
      padding: 2px 8px 2px 2px;
      border-radius: 11px;
      background: #292929;
      transition: all 0.15s linear;
      &:hover {
        opacity: 0.7;
      }
      & > figure {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 6px;
        border-radius: 10px;
        background: #e6e6e6;
        box-shadow: 0px 1.182px 2.245px 0px #fff inset,
          0px -2px 0px 0px #ababab inset;
      }
      p {
        color: #a5a5a5;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    border-top: 1px solid #282828;
    max-width: unset;
    padding-top: 20px;
    justify-content: center;
    margin-bottom: 20px;
    & > li {
      width: calc(100% / 3 - 53px);
      background: #292929;
      border-radius: 10px;
      padding: 4px;
      a {
        width: calc(100% - 10px);
        justify-content: center;
        background: #e6e6e6;
        box-shadow: 0px 1.182px 2.245px 0px #fff inset,
          0px -2px 0px 0px #ababab inset;
        figure {
          box-shadow: unset;
        }
      }
    }
  }
`;
export const FooterCopyright = styled.div`
  border-top: 1px solid #282828;
  padding-top: 30px;
  small {
    color: rgba(255, 255, 255, 0.43);
    font-size: 13px;
    font-weight: 500;
  }
`;
