import { Dispatch, createSlice } from "@reduxjs/toolkit";

interface DriverState {
  userProfile: any;
  isLoading: any;
  usdtBalance: any;
  refCode: any;
  balancesDB: any;
  tokenInfo: any;
}

const initialState: DriverState = {
  userProfile: null,
  isLoading: false,
  usdtBalance: null,
  tokenInfo: null,
  refCode: null,
  balancesDB: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserProfile(state, action) {
      state.userProfile = action.payload;
    },
    getTokenInfo(state, action) {
      state.tokenInfo = action.payload;
    },
    getUSDTBalance(state, action) {
      state.usdtBalance = action.payload;
    },
    getBalancesDB(state, action) {
      state.balancesDB = action.payload;
    },
    getUserRefCode(state, action) {
      state.refCode = action.payload;
    },
    isLoadingSuccess(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export function UserOverview(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getUserProfile(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getTokenInfo(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getTokenInfo(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function GetUSDTBalanceWallet(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getUSDTBalance(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function GetBalancesDB(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getBalancesDB(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function UserRefCode(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getUserRefCode(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function IsLoadingRedux(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.isLoadingSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

const userReducer = userSlice.reducer;

export default userReducer;
