import styled from "styled-components";

export const RoadmapContainer = styled.div`
  padding-top: 100px;
`;
export const RoadmapSlideItemInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 14px;
  height: 100%;
  min-height: 357px;
  @media screen and (max-width: 1023px) {
    min-height: 270px;
  }
`;
export const RoadmapContentSlide = styled.div`
  overflow: hidden;
`;
export const RoadmapSlide = styled.div<{ activeIndex: number }>`
  min-height: 480px;
  .swiper-wrapper {
    align-items: flex-end;
    padding-bottom: 100px;
    min-height: 430px;
  }
  .swiper-slide {
    border-radius: 16px;
    background: #202020;
    &.swiper-slide-active {
      background: #3e3e3e;
      transition: all 0.3s ease;
      ${RoadmapSlideItemInner} {
        min-height: 380px;
      }
      .item-title {
        font-size: 15px;
      }
      .item-sub-title {
        font-size: 16px;
      }
      .item-list {
        li {
          color: rgba(255, 255, 255, 0.74);
        }
      }
    }
  }
  .block-content {
    margin-top: auto;
  }
  .item-title {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
  }
  .item-sub-title {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .item-list {
    li {
      color: rgba(255, 255, 255, 0.43);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.15px;
      display: flex;
      align-items: center;
      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        background: rgba(255, 255, 255, 0.43);
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: unset;
    transform: translateX(-50%);
    bottom: 70px;
    width: 41px;
    height: 41px;
    background: url("/img/Landing/Roadmap/roadmap_icn_01.png") center / cover
      no-repeat;
    border-radius: 50%;
    &:after {
      content: none;
    }
  }
  .swiper-button-prev {
    left: 49.5%;
  }
  .swiper-button-next {
    transform: scaleX(-1) translateX(-50%);
    left: 50.5%;
  }
  .swiper-pagination {
    top: unset;
    bottom: 0;
  }
  .swiper-pagination-progressbar {
    background: url("/img/Landing/Roadmap/bg_decord.png");
    height: 40px;
    position: relative;
    overflow: hidden;
    span {
      background: unset;
      overflow: hidden;
      width: 100vw;
      max-width: 1120px;
      height: 100%;
      display: flex;
      position: static;
      &:after {
        width: 100%;
        height: 100%;
        background: url("/img/Landing/Roadmap/bg_decor_active.png");
        background-size: ${(props) => `${(5 / props.activeIndex) * 100}%`};
        content: "";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    min-height: 270px;
    margin-right: -36%;
    .swiper-wrapper {
      min-height: 270px;
    }
    .swiper-slide {
      min-height: 270px;
      &.swiper-slide-active {
        ${RoadmapSlideItemInner} {
          min-height: 270px;
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      bottom: 40px;
    }
    .swiper-button-prev {
      left: 35%;
    }
    .swiper-button-next {
      left: 36%;
    }
    .swiper-pagination-progressbar {
      display: none;
    }
  }
`;
export const RoadmapSlideItem = styled.div``;
